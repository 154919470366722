<div class="modal-div" (click)="cancel($event)">
  <ion-card>
    <div class="ion-margin">
      <h5>
        <p class="error-color-{{isError}}">
          <strong>{{text}}</strong>
        </p>
      </h5>
      <div class="ion-float-right ion-margin-bottom">
        <ion-buttons>
          <ion-row>
            <ion-col>
              <ion-button (click)="cancel($event)"><strong (click)="cancel($event)">Okay!</strong></ion-button>
            </ion-col>
          </ion-row>
        </ion-buttons>
      </div>
    </div>
  </ion-card>
</div>
