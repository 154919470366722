import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { BrowserInteractionHelper } from 'app/helpers/browser-interaction-helper';
import { CareerPathAlgorithmHelper } from 'app/helpers/career-path-algorithm-helper';
import { CareerPathHelper } from 'app/helpers/career-path-helper';
import { JsonValidationHelper } from 'app/helpers/json-validation-helper';
import { StorageHelper } from 'app/helpers/storage-helper';
import { UserHelper } from 'app/helpers/user-helper';
import { CAREER_SEARCH_PARAMETER_TERMS } from 'app/models/constants-app-texts';
import {
  AMOUNT_OF_PREVIOUS_CAREER_BRANCH_LOCAL_LOGICAL_NAME,
  ARE_TOOLTIPS_ACTIVE,
  CAREER_SEARCH_PARAMETER_KEYS,
  HAS_CAREER_SEARCHED_LOGICAL_NAME,
  PREVIOUS_COURSE_TEMPLATE_TREE_PARAMETER_LOGICAL_NAME,
} from 'app/models/constants-logical-names';
import { STANDARD_ERROR_CODE, SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { CourseTemplateTreeBranch } from 'app/models/course-template-tree-branch.model';
import { CourseTemplateTreeParameterRequest } from 'app/models/course-template-tree-parameter-request.model';
import { CourseTemplateTreeParameter } from 'app/models/course-template-tree-parameter.model';
import { CourseTemplateTree } from 'app/models/course-template-tree.model';
import { QualificationCategory } from 'app/models/qualification-category.model';
import { QualificationType } from 'app/models/qualification-type.model';
import { StoredCourseTemplateTreeBranch } from 'app/models/stored-course-template-tree-branch.model';
import { User } from 'app/models/user.model';
import { GetCourseTemplateTreesService } from 'app/services/get-course-template-trees.service';
import { GetQualificationsService } from 'app/services/get-qualifications.service';
import { UserWatchlistService } from 'app/services/user-watchlist.service';
import { CourseHelper } from '../../../helpers/course-helper';
import CourseTemplateTreeParameterJson from '../../../validators/course_template_tree_parameter_v1-0-0.json';

@Component({
  selector: 'app-tree-page',
  templateUrl: './tree.page.html',
  styleUrls: ['./tree.page.scss'],
})
export class TreePage implements OnInit {
  @ViewChild('header') header: any;
  @ViewChild('scrollButton') scrollButton: any;
  @ViewChild(IonContent) content: IonContent;
  public isSwiperCooldown: boolean = false;

  public qualificationCategories: QualificationCategory[] = [];
  public qualificationTypes: QualificationType[] = [];
  public areQualificationsLoading = false;
  public areTreesLoading = false;
  public courseTemplateTreeBranches: CourseTemplateTreeBranch[] = [];
  public searchedCourseTemplateTreeParameter: CourseTemplateTreeParameter = new CourseTemplateTreeParameter();
  public requirementQualificationType: QualificationType;
  public requirementQualificationCategory: QualificationCategory;
  public targetQualificationCategory: QualificationCategory;
  public searchFailed = false;
  public hasSearched = false;
  public isActiveTab = false;
  public chipSearchItems: { key: string; term: string; value: string }[] = [];
  public hasSearchedLogicalName = HAS_CAREER_SEARCHED_LOGICAL_NAME;
  public previousCareerBranchLogicalName = AMOUNT_OF_PREVIOUS_CAREER_BRANCH_LOCAL_LOGICAL_NAME;
  public amountOfPreviousCareerBranches = 0;
  public areTooltipsActive = true;
  public isLoggedIn = false;

  private hasRequestFailed = false;
  private user: User = null;
  private courseTemplateTrees: CourseTemplateTree[] = [];

  private errorCollection = '';
  private selectedCourseTemplateTreeBranches: CourseTemplateTreeBranch[] = [];

  constructor(
    public browserInteractionHelper: BrowserInteractionHelper,
    public storageHelper: StorageHelper,
    private careerPathHelper: CareerPathHelper,
    private careerPathAlgorithmHelper: CareerPathAlgorithmHelper,
    private courseHelper: CourseHelper,
    private courseTemplateTreeService: GetCourseTemplateTreesService,
    private userHelper: UserHelper,
    private userWatchlistService: UserWatchlistService,
    private qualificationService: GetQualificationsService,
  ) {}

  async ngOnInit() {
    this.amountOfPreviousCareerBranches = await this.careerPathHelper.getAmountOfPreviousBranchesForSession();
    if (this.amountOfPreviousCareerBranches === (undefined || null)) {
      this.amountOfPreviousCareerBranches = 0;
    }

    this.areQualificationsLoading = true;
    await this.getSelectionData();
    this.areQualificationsLoading = false;

    this.areTreesLoading = true;
    if (this.storageHelper.getSessionStorage(HAS_CAREER_SEARCHED_LOGICAL_NAME) === true) {
      await this.handleActiveSearch();
    }
    this.areTreesLoading = false;

    this.isLoggedIn = await this.userHelper.checkForUser();
    if (this.isLoggedIn === true) {
      this.user = await this.userHelper.loadUserData();
    }
  }

  async ionViewWillEnter() {
    this.isActiveTab = true;
    this.addAllFavoritedCourseTemplateTreeBranchesToArray();
    if (this.hasRequestFailed === true) {
      throw Error(this.errorCollection);
    }

    this.isLoggedIn = await this.userHelper.checkForUser();
    this.areTooltipsActive = await this.storageHelper.getPreferences(ARE_TOOLTIPS_ACTIVE);
  }

  ionViewWillLeave() {
    this.isActiveTab = false;
  }

  refresh(event) {
    setTimeout(() => {
      event.target.complete();
      window.location.reload();
    }, 1000);
  }

  public requirementQualificationTypeEventToInput(requirementQualificationType: QualificationType): void {
    this.requirementQualificationType = requirementQualificationType;
  }

  public requirementQualificationCategoryEventToInput(requirementQualificationCategory: QualificationCategory): void {
    this.requirementQualificationCategory = requirementQualificationCategory;
  }

  public targetQualificationCategoryEventToInput(targetQualificationCategory: QualificationCategory): void {
    this.targetQualificationCategory = targetQualificationCategory;
  }

  public async searchCourseTemplateTrees(
    selectedCourseTemplateTreeParameter: CourseTemplateTreeParameter,
  ): Promise<void> {
    this.areTreesLoading = true;
    this.careerPathAlgorithmHelper.removePreloadedTreeIds();
    this.searchedCourseTemplateTreeParameter = selectedCourseTemplateTreeParameter;
    const courseTemplateTreesSearchResult = await this.careerPathHelper.searchCourseTemplateTrees(
      selectedCourseTemplateTreeParameter,
      true,
    );

    if (courseTemplateTreesSearchResult === SYSTEM_ERROR_CODE) {
      this.hasSearched = false;
      this.storageHelper.setSessionStorage(HAS_CAREER_SEARCHED_LOGICAL_NAME, false);
      this.searchFailed = true;
      this.courseTemplateTrees = [];
      this.areTreesLoading = false;
      throw Error(this.courseHelper.errorCollection);
    }
    if (courseTemplateTreesSearchResult === STANDARD_ERROR_CODE) {
      this.hasSearched = false;
      this.storageHelper.setSessionStorage(HAS_CAREER_SEARCHED_LOGICAL_NAME, false);
      this.searchFailed = true;
      this.courseTemplateTrees = [];
      this.areTreesLoading = false;
      console.error(
        'Fehlerhafte Suchparameter' +
          JsonValidationHelper.validateJson(selectedCourseTemplateTreeParameter, CourseTemplateTreeParameterJson),
      );
    }
    this.searchFailed = false;
    this.courseTemplateTrees = courseTemplateTreesSearchResult as CourseTemplateTree[];
    this.courseTemplateTreeBranches = await this.careerPathHelper.buildCourseTemplateTrees(this.courseTemplateTrees);
    this.fillChips();
    this.hasSearched = true;
    this.storageHelper.setSessionStorage(HAS_CAREER_SEARCHED_LOGICAL_NAME, true);
    this.areTreesLoading = false;
  }

  private async addAllFavoritedCourseTemplateTreeBranchesToArray(): Promise<void> {
    this.selectedCourseTemplateTreeBranches = [];
    const favoritedCourseTemplateTreeBranches = await this.userWatchlistService.loadStoredCourseTemplateTreeBranches(
      this.user,
    );

    if (favoritedCourseTemplateTreeBranches === 401) {
      this.isLoggedIn = this.userHelper.logoutUser();
      return;
    }
    if (favoritedCourseTemplateTreeBranches === SYSTEM_ERROR_CODE) {
      this.hasRequestFailed = true;
      this.errorCollection += '\n' + this.userWatchlistService.errorMessage;
      return;
    }

    const searchParameters: CourseTemplateTreeParameter[] = [];

    for (const favoritedCourseTemplateTreeBranch of favoritedCourseTemplateTreeBranches as StoredCourseTemplateTreeBranch[]) {
      searchParameters.push(
        this.courseHelper.extractParameterFromStoredCourseTemplateTree(favoritedCourseTemplateTreeBranch),
      );
    }
    const courseTemplateTreeParameterRequest = new CourseTemplateTreeParameterRequest();
    courseTemplateTreeParameterRequest.courseTemplateTrees = searchParameters;
    courseTemplateTreeParameterRequest.maxResults = 100000000;
    courseTemplateTreeParameterRequest.startIndex = 0;
    const currentTree = await this.courseTemplateTreeService.searchCourseTrees(courseTemplateTreeParameterRequest);
    if (currentTree === SYSTEM_ERROR_CODE) {
      throw Error(this.courseTemplateTreeService.errorMessage);
    }

    this.selectedCourseTemplateTreeBranches.push(this.courseHelper.treeToBranch(currentTree[0]));
  }

  private async fillChips(): Promise<void> {
    this.chipSearchItems = [];

    if (this.searchedCourseTemplateTreeParameter === null) {
      return;
    }

    if (this.searchedCourseTemplateTreeParameter.zip !== undefined) {
      this.chipSearchItems.push({
        key: CAREER_SEARCH_PARAMETER_KEYS.distance,
        term: CAREER_SEARCH_PARAMETER_TERMS.distance,
        value:
          this.searchedCourseTemplateTreeParameter.zip + ' ' + this.searchedCourseTemplateTreeParameter.distance + 'km',
      });
    }

    if (this.searchedCourseTemplateTreeParameter.requirementQualificationCategoryId !== undefined) {
      this.chipSearchItems.push({
        key: CAREER_SEARCH_PARAMETER_KEYS.requirementQualificationCategory,
        term: CAREER_SEARCH_PARAMETER_TERMS.requirementQualificationCategory,
        value: this.qualificationCategories.find(
          (x) => x.id === this.searchedCourseTemplateTreeParameter.requirementQualificationCategoryId,
        ).name,
      });
    }

    if (this.searchedCourseTemplateTreeParameter.targetQualificationCategoryId !== undefined) {
      this.chipSearchItems.push({
        key: CAREER_SEARCH_PARAMETER_KEYS.targetQualificationCategory,
        term: CAREER_SEARCH_PARAMETER_TERMS.targetQualificationCategory,
        value: this.qualificationCategories.find(
          (x) => x.id === this.searchedCourseTemplateTreeParameter.targetQualificationCategoryId,
        ).name,
      });
    }

    if (this.searchedCourseTemplateTreeParameter.requirementQualificationTypeId !== undefined) {
      this.chipSearchItems.push({
        key: CAREER_SEARCH_PARAMETER_KEYS.requirementQualificationType,
        term: CAREER_SEARCH_PARAMETER_TERMS.requirementQualificationType,
        value: this.qualificationTypes.find(
          (x) => x.id === this.searchedCourseTemplateTreeParameter.requirementQualificationTypeId,
        ).name,
      });
    }
  }

  private async getSelectionData(): Promise<void> {
    const qualificationsResult = await this.qualificationService.getQualifications();
    if (qualificationsResult === SYSTEM_ERROR_CODE) {
      this.areQualificationsLoading = false;
      throw Error(this.qualificationService.errorMessage);
    }
    this.qualificationCategories = this.qualificationService.getCategories();
    this.qualificationTypes = this.qualificationService.getTypes();
  }

  private async handleActiveSearch(): Promise<void> {
    const hasCareerSearched = this.storageHelper.getSessionStorage(HAS_CAREER_SEARCHED_LOGICAL_NAME);
    if (hasCareerSearched !== null) {
      this.searchedCourseTemplateTreeParameter = JSON.parse(
        this.storageHelper.getSessionStorage(PREVIOUS_COURSE_TEMPLATE_TREE_PARAMETER_LOGICAL_NAME),
      );
      this.requirementQualificationCategory = this.qualificationCategories.find(
        (x) => x.id === this.searchedCourseTemplateTreeParameter.requirementQualificationCategoryId,
      );
      this.requirementQualificationType = this.qualificationTypes.find(
        (x) => x.id === this.searchedCourseTemplateTreeParameter.requirementQualificationTypeId,
      );
      this.targetQualificationCategory = this.qualificationCategories.find(
        (x) => x.id === this.searchedCourseTemplateTreeParameter.targetQualificationCategoryId,
      );
      await this.searchCourseTemplateTrees(this.searchedCourseTemplateTreeParameter);
      this.hasSearched = hasCareerSearched;
    }
  }
}
