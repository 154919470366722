import { Injectable } from '@angular/core';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';

@Injectable()
export class JsonValidationHelper {
  public static validateJson(json: object, validator: object): boolean | string {
    const ajv = new Ajv({ allErrors: true });
    addFormats(ajv);
    const validate = ajv.compile(validator);
    const isValid = validate(json);
    return isValid === true ? isValid : JSON.stringify(validate.errors);
  }
}
