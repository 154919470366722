import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonValidationHelper } from 'app/helpers/json-validation-helper';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { ErrorMessageParameter } from 'app/models/error-message-parameter';
import { RequestStatus } from 'app/models/request-status.model';
import { UserCourseRegistration } from 'app/models/user-course-registration';
import { UserCourseRegistrationParameter } from 'app/models/user-course-registration-parameter.model';
import { User } from 'app/models/user.model';
import { environment } from 'environments/environment';
import { Observable, lastValueFrom } from 'rxjs';
import RequestStatusJson from '../validators/request_status_v1-0-0.json';
import UserCourseRegistrationJson from '../validators/user_course_registration_v1-0-1.json';
import { ErrorMessageService } from './error-message.service';

@Injectable({
  providedIn: 'root',
})
export class UserCourseRegistrationService {
  public errorMessage = '';
  constructor(
    private http: HttpClient,
    private errorMessageService: ErrorMessageService,
  ) {}
  public async getUserCourseRegistrations(user: User): Promise<UserCourseRegistration[] | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };

      const userCourseRegistrationObservable = this.http.get<RequestStatus>(
        environment.apiUrlUserCourseRegistration.replace('{{user_id}}', user.id.toString()),
        options,
      );
      const userCourseRegistrations: UserCourseRegistration[] = [];

      const requestStatus = await lastValueFrom(userCourseRegistrationObservable);

      if (JsonValidationHelper.validateJson(requestStatus, RequestStatusJson) !== true) {
        const validation = JSON.stringify(JsonValidationHelper.validateJson(requestStatus, RequestStatusJson));
        this.errorMessage = validation;
        if (environment.production === false) {
          console.error(validation);
        }
        if (environment.production === true) {
          const error: ErrorMessageParameter = new ErrorMessageParameter();
          error.description = validation;
          this.errorMessageService.reportErrorMessage(error);
        }
        return userCourseRegistrations;
      }
      requestStatus.data.forEach((userCourseRegistration) => {
        if (JsonValidationHelper.validateJson(userCourseRegistration, UserCourseRegistrationJson) !== true) {
          const validation = JSON.stringify(JsonValidationHelper.validateJson(requestStatus, RequestStatusJson));
          if (environment.production === false) {
            console.error(JsonValidationHelper.validateJson(userCourseRegistration, UserCourseRegistrationJson));
            console.error(
              'Fehlerhaftes Vorschaubild "' + userCourseRegistration.name + '" mit ID: ' + userCourseRegistration.id,
            );
          }
          if (environment.production === true) {
            const error: ErrorMessageParameter = new ErrorMessageParameter();
            error.description = validation;
            this.errorMessageService.reportErrorMessage(error);
          }
          return;
        }
        userCourseRegistrations.unshift(userCourseRegistration);
      });

      return userCourseRegistrations;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }

  public registerUserForCourse(
    userCourseRegistration: UserCourseRegistrationParameter,
    token: string,
    userId: number,
  ): Observable<RequestStatus | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': token,
        }),
      };

      const body = JSON.stringify(userCourseRegistration);

      const observable = this.http.post<RequestStatus>(
        environment.apiUrlUserCourseRegistration.replace('{{user_id}}', userId.toString()),
        body,
        options,
      );

      return observable;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');

        return new Observable((observer) => {
          observer.next(401);
          observer.complete();
        });
      }
      this.errorMessage = error.message;
      return new Observable((observer) => {
        observer.next(SYSTEM_ERROR_CODE);
        observer.complete();
      });
    }
  }
}
