import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent implements OnInit {
  @Input() text: string = '';
  @Input() isError: boolean = false;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  public cancel(event: MouseEvent) {
    if (event.target === event.currentTarget) {
      return this.modalCtrl.dismiss(null, 'close');
    }
  }
}
