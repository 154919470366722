import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { ErrorMessageParameter } from 'app/models/error-message-parameter';
import { lastValueFrom } from 'rxjs';
import { JsonValidationHelper } from '../helpers/json-validation-helper';
import QualificationJson from '../validators/qualification_v1-0-0.json';
import RequestStatusJson from '../validators/request_status_v1-0-0.json';
import { environment } from './../../environments/environment';
import { QualificationCategory } from './../models/qualification-category.model';
import { QualificationType } from './../models/qualification-type.model';
import { Qualification } from './../models/qualification.model';
import { RequestStatus } from './../models/request-status.model';
import { ErrorMessageService } from './error-message.service';

@Injectable({
  providedIn: 'root',
})
export class GetQualificationsService {
  public errorMessage = '';
  private qualificationCategories: QualificationCategory[] = [];
  private qualificationTypes: QualificationType[] = [];

  constructor(
    private http: HttpClient,
    private errorMessageService: ErrorMessageService,
  ) {}

  public async getQualifications(): Promise<Qualification[] | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
        }),
      };

      const qualificationsObservable = await this.http.get<RequestStatus>(environment.apiUrlQualifications, options);
      const qualifications: Qualification[] = [];

      const requestStatus = await lastValueFrom(qualificationsObservable);

      // Qualifikationen validieren
      if (JsonValidationHelper.validateJson(requestStatus, RequestStatusJson) !== true) {
        const validation = JSON.stringify(JsonValidationHelper.validateJson(requestStatus, RequestStatusJson));
        if (environment.production === false) {
          console.error(validation);
        }
        if (environment.production === true) {
          const error: ErrorMessageParameter = new ErrorMessageParameter();
          error.description = validation;
          this.errorMessageService.reportErrorMessage(error);
        }
        return qualifications;
      }
      requestStatus.data.forEach((qualification) => {
        if (JsonValidationHelper.validateJson(qualification, QualificationJson) !== true) {
          const validation = JSON.stringify(JsonValidationHelper.validateJson(requestStatus, RequestStatusJson));
          if (environment.production === false) {
            console.error(JsonValidationHelper.validateJson(qualification, QualificationJson));
            console.error('Fehlerhafte Qualifikation "' + qualification.name + '" mit ID: ' + qualification.id);
          }
          if (environment.production === true) {
            const error: ErrorMessageParameter = new ErrorMessageParameter();
            error.description = validation;
            this.errorMessageService.reportErrorMessage(error);
          }
          return;
        }

        // Kategorien nur einmalig hinzufügen
        if (
          this.qualificationCategories.findIndex(
            (item: QualificationCategory) => item.id === qualification.qualificationCategoryId,
          ) === -1 &&
          qualification.qualificationCategoryName !== null
        ) {
          const qualificationCategory = new QualificationCategory();
          qualificationCategory.id = qualification.qualificationCategoryId;
          qualificationCategory.name = qualification.qualificationCategoryName;

          this.qualificationCategories.push(qualificationCategory);
        }

        // Typen nur einmalig hinzufügen
        if (
          this.qualificationTypes.findIndex(
            (item: QualificationCategory) => item.id === qualification.qualificationTypeId,
          ) === -1
        ) {
          const type = new QualificationType();
          type.id = qualification.qualificationTypeId;
          type.name = qualification.qualificationTypeName;

          this.qualificationTypes.push(type);
        }

        qualifications.push(qualification);
      });

      // Nachträgliche Sortierung der Daten nach dem Namen, da diese zufällig in den Array hinzugefügt werden
      qualifications.sort((a, b) => a.name.localeCompare(b.name));
      this.qualificationTypes.sort((a, b) => a.name.localeCompare(b.name));
      this.qualificationCategories.sort((a, b) => a.name.localeCompare(b.name));

      return qualifications;
    } catch (error) {
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }

  public getCategories(): QualificationCategory[] {
    return this.qualificationCategories;
  }
  public getTypes(): QualificationType[] {
    return this.qualificationTypes;
  }
}
