import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonValidationHelper } from 'app/helpers/json-validation-helper';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { CourseTemplateTreeParameterRequest } from 'app/models/course-template-tree-parameter-request.model';
import { CourseTemplateTree } from 'app/models/course-template-tree.model';
import { ErrorMessageParameter } from 'app/models/error-message-parameter';
import { RequestStatus } from 'app/models/request-status.model';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';
import CourseTemplateTreeJson from '../validators/course_template_tree_v1-0-0.json';
import RequestStatusJson from '../validators/request_status_v1-0-0.json';
import { ErrorMessageService } from './error-message.service';

@Injectable({
  providedIn: 'root',
})
export class GetCourseTemplateTreesService {
  public errorMessage = '';
  constructor(
    private http: HttpClient,
    private errorMessageService: ErrorMessageService,
  ) {}
  public async searchCourseTrees(
    courseTemplateTreeParameterRequest: CourseTemplateTreeParameterRequest,
  ): Promise<CourseTemplateTree[] | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
        }),
      };

      const body = JSON.stringify(courseTemplateTreeParameterRequest);
      const requestStatusObservable = this.http.post<RequestStatus>(
        environment.apiUrlCourseTemplateTrees,
        body,
        options,
      );
      const courseTemplateTrees: CourseTemplateTree[] = [];

      const requestStatus = await lastValueFrom(requestStatusObservable);

      if (JsonValidationHelper.validateJson(requestStatus, RequestStatusJson) !== true) {
        const validation = JSON.stringify(JsonValidationHelper.validateJson(requestStatus, RequestStatusJson));
        if (environment.production === false) {
          console.error(validation);
        }
        if (environment.production === true) {
          const error: ErrorMessageParameter = new ErrorMessageParameter();
          error.description = validation;
          this.errorMessageService.reportErrorMessage(error);
        }

        return courseTemplateTrees;
      }

      requestStatus.data.forEach((courseTemplateTree) => {
        if (JsonValidationHelper.validateJson(courseTemplateTree, CourseTemplateTreeJson) !== true) {
          const validation = JSON.stringify(
            JsonValidationHelper.validateJson(courseTemplateTree, CourseTemplateTreeJson),
          );
          if (environment.production === false) {
            console.error(validation);
            console.error(
              'Fehlerhafter Kursvorlagenbaum "' + courseTemplateTree.name + '" mit ID: ' + courseTemplateTree.id,
            );
          }
          if (environment.production === true) {
            const error: ErrorMessageParameter = new ErrorMessageParameter();
            error.description = validation;
            this.errorMessageService.reportErrorMessage(error);
          }

          return;
        }
        courseTemplateTrees.push(courseTemplateTree);
      });

      return courseTemplateTrees;
    } catch (error) {
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }
}
