import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonValidationHelper } from 'app/helpers/json-validation-helper';
import { STANDARD_ERROR_CODE, SUCCESS_ERROR_CODE, SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { ErrorMessageParameter } from 'app/models/error-message-parameter';
import { RequestStatus } from 'app/models/request-status.model';
import { User } from 'app/models/user.model';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';
import RequestStatusJson from '../validators/request_status_v1-0-0.json';
import { ErrorMessageService } from './error-message.service';

@Injectable({
  providedIn: 'root',
})
export class DeleteUserService {
  public errorMessage = '';
  constructor(
    private http: HttpClient,
    private errorMessageService: ErrorMessageService,
  ) {}

  public async deleteUser(user: User, deleteFromZls: boolean): Promise<number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };
      let returnObservable = null;
      if (deleteFromZls === true) {
        returnObservable = this.http.delete<RequestStatus>(
          environment.apiUrlUser.replace('{{user_id}}', user.id.toString()),
          options,
        );
      }
      if (deleteFromZls === false) {
        returnObservable = this.http.delete<RequestStatus>(
          environment.apiUrlUserProject.replace('{{user_id}}', user.id.toString()),
          options,
        );
      }

      const requestStatus: RequestStatus = await lastValueFrom(returnObservable);

      // Validieren der Server Antwort
      if (JsonValidationHelper.validateJson(requestStatus, RequestStatusJson) !== true) {
        const validation = JSON.stringify(JsonValidationHelper.validateJson(requestStatus, RequestStatusJson));
        if (environment.production === false) {
          console.error(validation);
        }
        if (environment.production === true) {
          const error: ErrorMessageParameter = new ErrorMessageParameter();
          error.description = validation;
          this.errorMessageService.reportErrorMessage(error);
        }
        // Eine Fehlermeldung für den Nutzer soll ausgegeben werden
        return STANDARD_ERROR_CODE;
      }

      return SUCCESS_ERROR_CODE;
    } catch (error) {
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }
}
