export class CourseTemplateTreeParameter {
  public requirementQualificationCategoryId: number;
  public targetQualificationCategoryId: number;
  public requirementQualificationTypeId: number;
  public zip: string;
  public distance: number;
  public countryWide: boolean;
  public templateIds: number[];
  public excludedTemplateIds?: number[];
}
