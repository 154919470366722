import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TooltipHelper } from 'app/helpers/tooltip-helper';
import { UserHelper } from 'app/helpers/user-helper';
import { HomeNavigationBarComponent } from '../home-navigation-bar/home-navigation-bar.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  public isChatOpen = false;
  public isGreetingActive = true;

  private isLoggedIn = false;

  constructor(
    private modalCtrl: ModalController,
    private userHelper: UserHelper,
    private tooltipHelper: TooltipHelper,
  ) {}

  ngOnInit() {}

  public toggleChat(): void {
    if (this.isGreetingActive === true) {
      this.removeChatbotAnimation();
      this.removeChatbotSpeechBubble();
      return;
    }

    this.isChatOpen = !this.isChatOpen;
    const navBar = document.getElementsByClassName('tab-button-chat')[0];
    if (this.isChatOpen === true) {
      navBar.classList.add('tab-activated');
      return;
    }
    navBar.classList.remove('tab-activated');
  }

  public async homeButtonClick(): Promise<void> {
    if (this.isGreetingActive === true) {
      this.removeChatbotAnimation();
      this.removeChatbotSpeechBubble();
    }

    this.isLoggedIn = await this.userHelper.checkForUser();
    if (window.innerWidth < 768) {
      this.openHomeNavigationModal();
    }
  }

  public triggerPulseAnimation(elementId: string, color: string): void {
    const chatbotPulse = document.getElementById(elementId);
    const chatbotPulseKeyframes = [
      {
        transform: 'scale(1)',
        '--webkit-transform': 'scale(1)',
        filter: `drop-shadow(0px 0px 0px var(--${color}))`,
      },
      {
        transform: 'scale(1.2)',
        '--webkit-transform': 'scale(1.2)',
        filter: `drop-shadow(0px 0px 6px var(--${color}))`,
      },
      {
        transform: 'scale(1)',
        '--webkit-transform': 'scale(1)',
        filter: `drop-shadow(0px 0px 0px var(--${color}))`,
      },
    ];

    const chatbotPulseOptions = {
      duration: 2000,
      iterations: Infinity,
    };

    chatbotPulse.animate(chatbotPulseKeyframes, chatbotPulseOptions);
  }

  public greetingCloseButtonClick(): void {
    this.homeButtonClick();
    this.removeChatbotAnimation();
    this.removeChatbotSpeechBubble();
  }

  private async openHomeNavigationModal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: HomeNavigationBarComponent,
      cssClass: 'home-navigation-modal',
      componentProps: {
        enableBackdropDismiss: true,
        isLoggedIn: this.isLoggedIn,
      },
    });
    modal.present();
    this.tooltipHelper.disappearAllHomeTooltips();
    this.tooltipHelper.disappearAllCareerSearchTooltips();
    this.tooltipHelper.disappearAllCourseSearchTooltips();
    const navBar = document.getElementsByClassName('tab-button-nav')[0];
    navBar.classList.add('tab-activated');

    await modal.onWillDismiss();
    navBar.classList.remove('tab-activated');
    this.tooltipHelper.disappearAllMenuTooltips();
    this.tooltipHelper.homeToolTipBehavior();
    this.tooltipHelper.careerSearchTooltipBehavior();
    this.tooltipHelper.courseSearchTooltipBehavior();
  }

  private removeChatbotAnimation(): void {
    const chatbotPulse = document.getElementById('chatbot-pulse');

    chatbotPulse.getAnimations().forEach((animation) => {
      animation.cancel();
    });
  }

  private removeChatbotSpeechBubble(): void {
    const chatbotSpeechBubble = document.getElementById('chatbot-speech-bubble');
    chatbotSpeechBubble.style.display = 'none';
    this.isGreetingActive = false;
  }
}
