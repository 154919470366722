import { Injectable } from '@angular/core';
import { AMOUNT_OF_PREVIOUS_CAREER_BRANCH_SESSION_LOGICAL_NAME } from 'app/models/constants-logical-names';
import { STANDARD_ERROR_CODE, SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { CourseTemplateTreeBranch } from 'app/models/course-template-tree-branch.model';
import { CourseTemplateTreeParameterRequest } from 'app/models/course-template-tree-parameter-request.model';
import { CourseTemplateTreeParameter } from 'app/models/course-template-tree-parameter.model';
import { CourseTemplateTree } from 'app/models/course-template-tree.model';
import { GetCourseTemplateTreesService } from 'app/services/get-course-template-trees.service';
import { CareerPathAlgorithmHelper } from './career-path-algorithm-helper';
import { StorageHelper } from './storage-helper';

@Injectable({
  providedIn: 'root',
})
export class CareerPathHelper {
  constructor(
    private careerPathAlgorithmHelper: CareerPathAlgorithmHelper,
    private courseTemplateTreeService: GetCourseTemplateTreesService,
    private storageHelper: StorageHelper,
  ) {}

  public async increaseAmountOfPreviousBranchesForSession(): Promise<void> {
    let amountOfPreviousBranches = await this.getAmountOfPreviousBranchesForSession();
    if (!amountOfPreviousBranches) {
      amountOfPreviousBranches = 0;
    }
    this.storageHelper.setSessionStorage(
      AMOUNT_OF_PREVIOUS_CAREER_BRANCH_SESSION_LOGICAL_NAME,
      amountOfPreviousBranches + 1,
    );
    return;
  }

  public async decreaseAmountOfPreviousBranchesForSession(): Promise<void> {
    let amountOfPreviousBranches = await this.getAmountOfPreviousBranchesForSession();
    if (!amountOfPreviousBranches) {
      amountOfPreviousBranches = 0;
      this.storageHelper.setSessionStorage(
        AMOUNT_OF_PREVIOUS_CAREER_BRANCH_SESSION_LOGICAL_NAME,
        amountOfPreviousBranches,
      );
      return;
    }
    this.storageHelper.setSessionStorage(
      AMOUNT_OF_PREVIOUS_CAREER_BRANCH_SESSION_LOGICAL_NAME,
      amountOfPreviousBranches - 1,
    );
    return;
  }

  public async getAmountOfPreviousBranchesForSession(): Promise<number> {
    const amountOfSkippedBranchesInSession = this.storageHelper.getSessionStorage(
      AMOUNT_OF_PREVIOUS_CAREER_BRANCH_SESSION_LOGICAL_NAME,
    );
    return amountOfSkippedBranchesInSession;
  }

  public async searchCourseTemplateTrees(
    selectedCourseTemplateTreeParameter: CourseTemplateTreeParameter,
    doesSkipIds: boolean,
  ): Promise<number | CourseTemplateTree[]> {
    const courseTemplateTreeParameterRequest = new CourseTemplateTreeParameterRequest();
    if (doesSkipIds === true) {
      courseTemplateTreeParameterRequest.excludedTemplateIds = await this.careerPathAlgorithmHelper.getSkippedPathIds();
    }
    courseTemplateTreeParameterRequest.courseTemplateTrees = [];
    courseTemplateTreeParameterRequest.courseTemplateTrees.push(selectedCourseTemplateTreeParameter);
    courseTemplateTreeParameterRequest.maxResults = 1;
    courseTemplateTreeParameterRequest.startIndex = 0;

    const courseTemplateTreesSearchResult = await this.courseTemplateTreeService.searchCourseTrees(
      courseTemplateTreeParameterRequest,
    );
    if (
      courseTemplateTreesSearchResult === (SYSTEM_ERROR_CODE || courseTemplateTreesSearchResult === STANDARD_ERROR_CODE)
    ) {
      return courseTemplateTreesSearchResult;
    }
    return courseTemplateTreesSearchResult as CourseTemplateTree[];
  }

  public async hasPotentialResultsInCareerSearch(
    selectedCourseTemplateTreeParameter: CourseTemplateTreeParameter,
  ): Promise<boolean> {
    const courseTemplateTreesSearchResult = await this.searchCourseTemplateTrees(
      selectedCourseTemplateTreeParameter,
      false,
    );

    if (courseTemplateTreesSearchResult === SYSTEM_ERROR_CODE) {
      return false;
    }
    if (courseTemplateTreesSearchResult === STANDARD_ERROR_CODE) {
      return false;
    }
    if (Array.isArray(courseTemplateTreesSearchResult) === true) {
      if ((courseTemplateTreesSearchResult as CourseTemplateTree[]).length > 0) {
        return true;
      }
    }
  }

  public async buildCourseTemplateTrees(
    courseTemplateTrees: CourseTemplateTree[],
  ): Promise<CourseTemplateTreeBranch[]> {
    const courseTemplateTreeBranches: CourseTemplateTreeBranch[] = [];
    for (const courseTreeItemDepth1 of courseTemplateTrees) {
      const courseTree = new CourseTemplateTreeBranch();
      courseTree.depth = courseTreeItemDepth1.depth;
      courseTree.id = courseTreeItemDepth1.id;
      courseTree.name = courseTreeItemDepth1.name;

      if (courseTreeItemDepth1.nextPossibilities !== undefined && courseTreeItemDepth1.nextPossibilities.length !== 0) {
        for (const courseTreeItemDepth2 of courseTreeItemDepth1.nextPossibilities) {
          courseTree.nextBranch = new CourseTemplateTreeBranch();
          courseTree.nextBranch.depth = courseTreeItemDepth2.depth;
          courseTree.nextBranch.id = courseTreeItemDepth2.id;
          courseTree.nextBranch.name = courseTreeItemDepth2.name.toString();
          if (JSON.stringify(courseTemplateTreeBranches).includes(JSON.stringify(courseTree)) === true) {
            continue;
          }

          courseTemplateTreeBranches.push(structuredClone(courseTree));

          continue;
        }
        continue;
      }
      if (JSON.stringify(courseTemplateTreeBranches).includes(JSON.stringify(courseTree)) === true) {
        continue;
      }
      courseTemplateTreeBranches.push(structuredClone(courseTree));

      continue;
    }
    return courseTemplateTreeBranches;
  }
}
