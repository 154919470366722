import { Injectable } from '@angular/core';
import { CareerAlgorithmParameters } from 'app/models/career-algorithm-parameters';
import { ALGORITHM_DISLIKES_UNTIL_SKIP, ALGORITHM_SWIPES_UNTIL_SKIP } from 'app/models/constants-algorithm';
import { CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY } from 'app/models/constants-logical-names';
import { MATOMO_LOGICAL_NAME_CAREER_AUTO_SKIPPED } from 'app/models/constants-matomo';
import { CourseTemplateTreeBranch } from 'app/models/course-template-tree-branch.model';
import { MatomoHelper } from './matomo-helper';
import { StorageHelper } from './storage-helper';

@Injectable({
  providedIn: 'root',
})
export class CareerPathAlgorithmHelper {
  constructor(
    private storageHelper: StorageHelper,
    private matomoHelper: MatomoHelper,
  ) {}
  public async createParametersForCurrentTree(courseTemplateTreeId: number): Promise<void> {
    let careerAlgorithmParametersArray: CareerAlgorithmParameters[] = await this.storageHelper.getPreferences(
      CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY,
    );
    careerAlgorithmParametersArray = careerAlgorithmParametersArray === null ? [] : careerAlgorithmParametersArray;
    if (careerAlgorithmParametersArray.find((x) => x.courseTemplateTreeId === courseTemplateTreeId)) {
      this.resetParametersForTree(courseTemplateTreeId);
      return;
    }
    const careerAlgorithmParameters = new CareerAlgorithmParameters();
    careerAlgorithmParameters.courseTemplateTreeId = courseTemplateTreeId;
    careerAlgorithmParameters.amountOfDislikes = 0;
    careerAlgorithmParameters.amountOfSwipes = 0;
    careerAlgorithmParameters.amountOfExtraSwipes = 0;
    careerAlgorithmParameters.amountOfExtraDislikes = 0;
    careerAlgorithmParameters.amountOfExtraPositiveInteractions = 0;
    careerAlgorithmParametersArray.push(careerAlgorithmParameters);
    await this.storageHelper.setPreferences(CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY, careerAlgorithmParametersArray);
  }

  public async getSkippedPathIds(): Promise<number[] | undefined> {
    let careerAlgorithmParametersArray: CareerAlgorithmParameters[] = await this.storageHelper.getPreferences(
      CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY,
    );
    if (!careerAlgorithmParametersArray) {
      return undefined;
    }
    const skippedIds: number[] = [];
    careerAlgorithmParametersArray = careerAlgorithmParametersArray.filter((x) => x.dateSkipped !== undefined);
    for (const parameters of careerAlgorithmParametersArray) {
      skippedIds.push(parameters.courseTemplateTreeId);
    }

    return skippedIds === undefined ? [] : skippedIds;
  }

  public async getPreloadedTreeIds(): Promise<number[]> {
    let careerAlgorithmParametersArray: CareerAlgorithmParameters[] = await this.storageHelper.getPreferences(
      CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY,
    );
    if (!careerAlgorithmParametersArray) {
      return [];
    }
    const skippedIds: number[] = [];
    careerAlgorithmParametersArray = careerAlgorithmParametersArray.filter((x) => x.dateSkipped === undefined);
    for (const parameters of careerAlgorithmParametersArray) {
      skippedIds.push(parameters.courseTemplateTreeId);
    }

    return skippedIds === undefined ? [] : skippedIds;
  }

  public async removePreloadedTreeIds(): Promise<void> {
    let careerAlgorithmParametersArray: CareerAlgorithmParameters[] = await this.storageHelper.getPreferences(
      CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY,
    );
    if (!careerAlgorithmParametersArray) {
      return;
    }
    careerAlgorithmParametersArray = careerAlgorithmParametersArray.filter((x) => x.dateSkipped !== undefined);
    await this.storageHelper.setPreferences(CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY, careerAlgorithmParametersArray);
  }

  public async doesHavePreLoadedTrees(): Promise<boolean> {
    if ((await this.getPreloadedTreeIds()).length > 1) {
      return true;
    }
    return false;
  }

  public async resetAllPaths() {
    const paths = (await this.getSkippedPathIds()) as number[];

    for (const path of paths) {
      await this.resetParametersForTree(path);
    }
  }

  public async getMostRecentSkippedPathId(): Promise<number> {
    return (await this.getSkippedPathIds()).slice(-1)[0];
  }

  public async addDislikeToAlgorithmParameters(courseTemplateTreeBranch: CourseTemplateTreeBranch): Promise<boolean> {
    const careerAlgorithmParametersArray: CareerAlgorithmParameters[] = await this.storageHelper.getPreferences(
      CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY,
    );
    if (!careerAlgorithmParametersArray) {
      return;
    }
    const careerAlgorithmParameters: CareerAlgorithmParameters = careerAlgorithmParametersArray.find(
      (x) => x.courseTemplateTreeId === courseTemplateTreeBranch.id,
    );
    if (!careerAlgorithmParameters) {
      return;
    }
    const indexOfElementToReplace = careerAlgorithmParametersArray.indexOf(careerAlgorithmParameters);
    careerAlgorithmParameters.amountOfDislikes += 1;
    careerAlgorithmParametersArray.splice(indexOfElementToReplace, 1, careerAlgorithmParameters);
    await this.storageHelper.setPreferences(CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY, careerAlgorithmParametersArray);
    return this.checkIfDateShouldBeSkipped(courseTemplateTreeBranch);
  }

  public async removeDislikeFromAlgorithmParameters(courseTemplateTreeId: number): Promise<void> {
    const careerAlgorithmParametersArray: CareerAlgorithmParameters[] = await this.storageHelper.getPreferences(
      CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY,
    );
    if (!careerAlgorithmParametersArray) {
      return;
    }
    const careerAlgorithmParameters: CareerAlgorithmParameters = careerAlgorithmParametersArray.find(
      (x) => x.courseTemplateTreeId === courseTemplateTreeId,
    );
    if (!careerAlgorithmParameters) {
      return;
    }
    const indexOfElementToReplace = careerAlgorithmParametersArray.indexOf(careerAlgorithmParameters);
    careerAlgorithmParameters.amountOfDislikes -= 1;
    careerAlgorithmParametersArray.splice(indexOfElementToReplace, 1, careerAlgorithmParameters);
    await this.storageHelper.setPreferences(CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY, careerAlgorithmParametersArray);
  }

  public async addDateSkippedToAlgorithmParameters(courseTemplateTreeId: number): Promise<void> {
    const careerAlgorithmParametersArray: CareerAlgorithmParameters[] = await this.storageHelper.getPreferences(
      CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY,
    );
    if (!careerAlgorithmParametersArray) {
      return;
    }
    const careerAlgorithmParameters: CareerAlgorithmParameters = careerAlgorithmParametersArray.find(
      (x) => x.courseTemplateTreeId === courseTemplateTreeId,
    );
    if (!careerAlgorithmParameters) {
      return;
    }
    const indexOfElementToReplace = careerAlgorithmParametersArray.indexOf(careerAlgorithmParameters);
    careerAlgorithmParameters.dateSkipped = new Date().toISOString();
    careerAlgorithmParametersArray.splice(indexOfElementToReplace, 1, careerAlgorithmParameters);
    await this.storageHelper.setPreferences(CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY, careerAlgorithmParametersArray);
  }

  public async removeDateSkippedFromAlgorithmParameters(courseTemplateTreeId: number): Promise<void> {
    const careerAlgorithmParametersArray: CareerAlgorithmParameters[] = await this.storageHelper.getPreferences(
      CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY,
    );
    if (!careerAlgorithmParametersArray) {
      return;
    }
    const careerAlgorithmParameters: CareerAlgorithmParameters = careerAlgorithmParametersArray.find(
      (x) => x.courseTemplateTreeId === courseTemplateTreeId,
    );
    if (!careerAlgorithmParameters) {
      return;
    }
    const indexOfElementToReplace = careerAlgorithmParametersArray.indexOf(careerAlgorithmParameters);
    careerAlgorithmParameters.dateSkipped = undefined;
    careerAlgorithmParametersArray.splice(indexOfElementToReplace, 1, careerAlgorithmParameters);
    await this.storageHelper.setPreferences(CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY, careerAlgorithmParametersArray);
  }

  public async resetParametersForTree(courseTemplateTreeId: number): Promise<void> {
    const careerAlgorithmParametersArray: CareerAlgorithmParameters[] = await this.storageHelper.getPreferences(
      CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY,
    );
    if (!careerAlgorithmParametersArray) {
      return;
    }
    const careerAlgorithmParameters: CareerAlgorithmParameters = careerAlgorithmParametersArray.find(
      (x) => x.courseTemplateTreeId === courseTemplateTreeId,
    );
    if (!careerAlgorithmParameters) {
      return;
    }
    const indexOfElementToReplace = careerAlgorithmParametersArray.indexOf(careerAlgorithmParameters);
    careerAlgorithmParameters.courseTemplateTreeId = courseTemplateTreeId;
    careerAlgorithmParameters.amountOfDislikes = 0;
    careerAlgorithmParameters.amountOfSwipes = 0;
    careerAlgorithmParameters.dateSkipped = undefined;
    careerAlgorithmParametersArray.splice(indexOfElementToReplace, 1, careerAlgorithmParameters);
    await this.storageHelper.setPreferences(CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY, careerAlgorithmParametersArray);
  }

  public async addAmountOfExtraDislikes(courseTemplateTreeId: number): Promise<void> {
    const careerAlgorithmParametersArray: CareerAlgorithmParameters[] = await this.storageHelper.getPreferences(
      CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY,
    );
    if (!careerAlgorithmParametersArray) {
      return;
    }
    const careerAlgorithmParameters: CareerAlgorithmParameters = careerAlgorithmParametersArray.find(
      (x) => x.courseTemplateTreeId === courseTemplateTreeId,
    );
    if (!careerAlgorithmParameters) {
      return;
    }
    const indexOfElementToReplace = careerAlgorithmParametersArray.indexOf(careerAlgorithmParameters);
    careerAlgorithmParameters.amountOfExtraDislikes = careerAlgorithmParameters.amountOfDislikes;
    careerAlgorithmParametersArray.splice(indexOfElementToReplace, 1, careerAlgorithmParameters);
    await this.storageHelper.setPreferences(CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY, careerAlgorithmParametersArray);
  }

  private async checkIfDateShouldBeSkipped(courseTemplateTree: CourseTemplateTreeBranch): Promise<boolean> {
    const allParameters: CareerAlgorithmParameters[] = await this.storageHelper.getPreferences(
      CAREER_ALGORITHM_PARAMETERS_ARRAY_KEY,
    );
    const currentParameters: CareerAlgorithmParameters = allParameters.find(
      (x) => x.courseTemplateTreeId === courseTemplateTree.id,
    );
    currentParameters.amountOfExtraSwipes === undefined ? 0 : currentParameters.amountOfExtraSwipes;
    currentParameters.amountOfExtraSwipes =
      currentParameters.amountOfExtraSwipes === undefined ? 0 : currentParameters.amountOfExtraSwipes;
    currentParameters.amountOfExtraDislikes =
      currentParameters.amountOfExtraDislikes === undefined ? 0 : currentParameters.amountOfExtraDislikes;
    if (currentParameters.amountOfDislikes - currentParameters.amountOfExtraDislikes >= ALGORITHM_DISLIKES_UNTIL_SKIP) {
      await this.addDateSkippedToAlgorithmParameters(courseTemplateTree.id);
      this.matomoHelper.trackMatomo(MATOMO_LOGICAL_NAME_CAREER_AUTO_SKIPPED, [
        {
          courseTemplateId: courseTemplateTree.id,
          courseTemplateName: courseTemplateTree.name,
        },
      ]);
      return true;
    }
    if (currentParameters.amountOfSwipes - currentParameters.amountOfExtraSwipes >= ALGORITHM_SWIPES_UNTIL_SKIP) {
      await this.addDateSkippedToAlgorithmParameters(courseTemplateTree.id);
      this.matomoHelper.trackMatomo(MATOMO_LOGICAL_NAME_CAREER_AUTO_SKIPPED, [
        {
          courseTemplateId: courseTemplateTree.id,
          courseTemplateName: courseTemplateTree.name,
        },
      ]);
      return true;
    }
    return false;
  }
}
