import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceHelper } from 'app/helpers/device-helper';
import { MatomoHelper } from 'app/helpers/matomo-helper';
import { StorageHelper } from 'app/helpers/storage-helper';
import { TooltipHelper } from 'app/helpers/tooltip-helper';
import {
  QUALIFICATION_TYPE_SELECT_CAREER_TEXT,
  REQUIREMENT_QUALIFICATION_CATEGORY_SELECT_CAREER_TEXT,
  TARGET_QUALIFICATION_CATEGORY_SELECT_TEXT,
  USER_AGREEMENT_QUESTION,
} from 'app/models/constants-app-texts';
import { DISTANCE_VALUES } from 'app/models/constants-app-values';
import {
  IS_USER_AGREEMENT_AGREED_LOGICAL_NAME,
  PREVIOUS_COURSE_TEMPLATE_TREE_PARAMETER_LOGICAL_NAME,
} from 'app/models/constants-logical-names';
import { MATOMO_LOGICAL_NAME_CAREER_SEARCH } from 'app/models/constants-matomo';
import { STANDARD_ERROR_TEXT } from 'app/models/constants-status';
import { CourseTemplateTreeParameter } from 'app/models/course-template-tree-parameter.model';
import { QualificationCategory } from '../../../models/qualification-category.model';
import { QualificationType } from '../../../models/qualification-type.model';

@Component({
  selector: 'app-career-search',
  templateUrl: './career-search.component.html',
  styleUrls: ['./career-search.component.scss'],
})
export class CareerSearchComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Output() searchEvent = new EventEmitter<CourseTemplateTreeParameter>();
  @Output() requirementQualificationTypeEvent = new EventEmitter<QualificationType>();
  @Output() requirementQualificationCategoryEvent = new EventEmitter<QualificationCategory>();
  @Output() targetQualificationCategoryEvent = new EventEmitter<QualificationCategory>();
  @Input() searchFailed = false;
  @Input() areTreesLoading = false;
  @Input() qualificationCategories: QualificationCategory[] = [];
  @Input() qualificationTypes: QualificationType[] = [];
  @Input() isLoggedIn = false;

  public readonly distanceValues = DISTANCE_VALUES;
  public readonly requirementQualificationTypeText = QUALIFICATION_TYPE_SELECT_CAREER_TEXT;
  public readonly requirementQualificationCategoryText = REQUIREMENT_QUALIFICATION_CATEGORY_SELECT_CAREER_TEXT;
  public readonly targetQualificationCategoryText = TARGET_QUALIFICATION_CATEGORY_SELECT_TEXT;
  public readonly standardErrorText = STANDARD_ERROR_TEXT;
  public readonly userAgreementQuestion = USER_AGREEMENT_QUESTION;

  public selectedRequirementQualificationCategory: QualificationCategory = null;
  public selectedTargetQualificationCategory: QualificationCategory = null;
  public selectedRequirementQualificationType: QualificationType = null;
  public isUserAgreementAgreed = false;

  private selectedZip = '';
  private selectedDistance = 30;
  private isCountryWide = true;

  public formGroup: FormGroup = this.formBuilder.group({
    isCountryWide: [this.isCountryWide],
    zipCodeInput: ['', [Validators.minLength(5), Validators.maxLength(5), Validators.required]],
    distanceSelect: [this.selectedDistance, Validators.required],
    requirementQualificationCategorySelect: [this.selectedRequirementQualificationCategory],
    targetQualificationCategorySelect: [this.selectedTargetQualificationCategory],
    qualificationTypeSelect: [this.selectedRequirementQualificationType, Validators.required],
    userAgreementCheckbox: [this.isLoggedIn, Validators.requiredTrue],
  });

  constructor(
    public deviceHelper: DeviceHelper,
    public sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private matomoHelper: MatomoHelper,
    private storageHelper: StorageHelper,
    private tooltipHelper: TooltipHelper,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    await this.deviceHelper.checkUserDevice();
  }

  async ngAfterViewInit() {
    await this.fillForm();
    this.changeIsCountryWide(true);
    this.formGroup.controls.userAgreementCheckbox.setValue(this.isLoggedIn);
    if (this.isLoggedIn === false) {
      this.formGroup.controls.userAgreementCheckbox.setValue(this.isUserAgreementAgreed);
    }
    this.tooltipHelper.careerSearchTooltipBehavior();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  changeIsCountryWide(isCountryWide: any) {
    this.isCountryWide = isCountryWide;
    if (this.isCountryWide === true) {
      this.formGroup.controls.distanceSelect.disable();
      this.formGroup.controls.zipCodeInput.disable();
      document.getElementById('zip-input-browse').classList.add('disabled');
    }
    if (this.isCountryWide === false) {
      this.formGroup.controls.distanceSelect.enable();
      this.formGroup.controls.zipCodeInput.enable();
      document.getElementById('zip-input-browse').classList.remove('disabled');
    }
  }

  public toggleIsCountryWide(): void {
    this.formGroup.controls.isCountryWide.setValue(!this.formGroup.controls.isCountryWide.getRawValue());
    this.changeIsCountryWide(this.formGroup.controls.isCountryWide.getRawValue());
  }

  public changeSelectedZip(selectedZip: string): void {
    this.selectedZip = selectedZip;
  }
  public changeSelectedDistance(selectedDistance: number): void {
    this.selectedDistance = selectedDistance;
  }

  public searchCourseTemplateTrees(): void {
    const courseTemplateTreeParameter = new CourseTemplateTreeParameter();
    if (this.isCountryWide === false) {
      if (this.selectedZip !== '') {
        courseTemplateTreeParameter.zip = this.selectedZip === '' ? null : this.selectedZip;
        courseTemplateTreeParameter.distance = this.selectedDistance;
      }
    }
    if (this.isCountryWide === true) {
      courseTemplateTreeParameter.countryWide = true;
    }

    if (
      this.selectedRequirementQualificationCategory !== undefined &&
      this.selectedRequirementQualificationCategory !== null
    ) {
      courseTemplateTreeParameter.requirementQualificationCategoryId = this.selectedRequirementQualificationCategory.id;
    }
    if (this.selectedTargetQualificationCategory !== undefined && this.selectedTargetQualificationCategory !== null) {
      courseTemplateTreeParameter.targetQualificationCategoryId = this.selectedTargetQualificationCategory.id;
    }
    if (this.selectedRequirementQualificationType !== undefined && this.selectedRequirementQualificationType !== null) {
      courseTemplateTreeParameter.requirementQualificationTypeId = this.selectedRequirementQualificationType.id;
    }

    this.matomoHelper.trackMatomo(MATOMO_LOGICAL_NAME_CAREER_SEARCH, []);

    this.storageHelper.setSessionStorage(
      PREVIOUS_COURSE_TEMPLATE_TREE_PARAMETER_LOGICAL_NAME,
      JSON.stringify(courseTemplateTreeParameter),
    );

    this.requirementQualificationTypeEvent.emit(this.selectedRequirementQualificationType);
    this.requirementQualificationCategoryEvent.emit(this.selectedRequirementQualificationCategory);
    this.targetQualificationCategoryEvent.emit(this.selectedTargetQualificationCategory);
    this.searchEvent.emit(courseTemplateTreeParameter);
  }

  public changeSelectedQualificationType(qualificationType: QualificationType): void {
    this.selectedRequirementQualificationType = qualificationType;

    if (qualificationType === null) {
      this.formGroup.controls.qualificationTypeSelect.setValue(null);
    }
  }

  public changeSelectedRequirementQualificationCategory(requirementQualificationCategory: QualificationCategory): void {
    this.selectedRequirementQualificationCategory = requirementQualificationCategory;

    if (requirementQualificationCategory === null) {
      this.formGroup.controls.requirementQualificationCategorySelect.setValue(null);
    }
  }

  public changeSelectedTargetQualificationCategory(targetQualificationCategory: QualificationCategory): void {
    this.selectedTargetQualificationCategory = targetQualificationCategory;

    if (targetQualificationCategory === null) {
      this.formGroup.controls.targetQualificationCategorySelect.setValue(null);
    }
  }

  public async changeIsUserAgreementAgreed(isUserAgreementAgreed: any): Promise<void> {
    this.isUserAgreementAgreed = isUserAgreementAgreed;
    if (this.isUserAgreementAgreed === false) {
      this.storageHelper.setSessionStorage(IS_USER_AGREEMENT_AGREED_LOGICAL_NAME, undefined);
    }
  }

  private async fillForm(): Promise<void> {
    const courseTemplateTreeParameter: CourseTemplateTreeParameter = JSON.parse(
      await this.storageHelper.getSessionStorage(PREVIOUS_COURSE_TEMPLATE_TREE_PARAMETER_LOGICAL_NAME),
    );
    this.isUserAgreementAgreed = JSON.parse(
      await this.storageHelper.getSessionStorage(IS_USER_AGREEMENT_AGREED_LOGICAL_NAME),
    );
    if (this.isUserAgreementAgreed === null) {
      this.isUserAgreementAgreed = false;
    }

    if (courseTemplateTreeParameter === null) {
      return;
    }

    if (courseTemplateTreeParameter.countryWide !== undefined) {
      this.changeIsCountryWide(courseTemplateTreeParameter.countryWide);
      this.formGroup.controls.isCountryWide.setValue(courseTemplateTreeParameter.countryWide);
    }

    if (courseTemplateTreeParameter.zip !== undefined) {
      this.changeSelectedDistance(courseTemplateTreeParameter.distance);
      this.formGroup.controls.distanceSelect.setValue(courseTemplateTreeParameter.distance);
      this.changeSelectedZip(courseTemplateTreeParameter.zip);
      this.formGroup.controls.zipCodeInput.setValue(courseTemplateTreeParameter.zip);
    }

    if (courseTemplateTreeParameter.targetQualificationCategoryId !== undefined) {
      this.selectedTargetQualificationCategory = this.qualificationCategories.find(
        (x) => x.id === courseTemplateTreeParameter.targetQualificationCategoryId,
      );
      this.formGroup.controls.targetQualificationCategorySelect.setValue(this.selectedTargetQualificationCategory);
    }

    if (courseTemplateTreeParameter.requirementQualificationCategoryId !== undefined) {
      this.selectedRequirementQualificationCategory = this.qualificationCategories.find(
        (x) => x.id === courseTemplateTreeParameter.requirementQualificationCategoryId,
      );
      this.formGroup.controls.requirementQualificationCategorySelect.setValue(
        this.selectedRequirementQualificationCategory,
      );
    }

    if (courseTemplateTreeParameter.requirementQualificationTypeId !== undefined) {
      this.selectedRequirementQualificationType = this.qualificationTypes.find(
        (x) => x.id === courseTemplateTreeParameter.requirementQualificationTypeId,
      );
      this.formGroup.controls.qualificationTypeSelect.setValue(this.selectedRequirementQualificationType);
    }
  }
}
