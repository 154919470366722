import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonValidationHelper } from 'app/helpers/json-validation-helper';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { ErrorMessageParameter } from 'app/models/error-message-parameter';
import { PreviewImage } from 'app/models/preview-image.model';
import { RequestStatus } from 'app/models/request-status.model';
import { User } from 'app/models/user.model';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';
import PreviewImageJson from '../validators/preview_image_v1-0-0.json';
import RequestStatusJson from '../validators/request_status_v1-0-0.json';
import { ErrorMessageService } from './error-message.service';

@Injectable({
  providedIn: 'root',
})
export class GetUserFilesService {
  public errorMessage = '';
  constructor(
    private http: HttpClient,
    private errorMessageService: ErrorMessageService,
  ) {}

  public async getUserPreviewImages(user: User): Promise<PreviewImage[] | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
      };

      const previewImagesObservable = this.http.get<RequestStatus>(
        environment.apiUrlUserFilesGetPreviews.replace('{{user_id}}', user.id.toString()),
        options,
      );
      const previewImages: PreviewImage[] = [];

      const requestStatus = await lastValueFrom(previewImagesObservable);

      if (JsonValidationHelper.validateJson(requestStatus, RequestStatusJson) !== true) {
        const validation = JSON.stringify(JsonValidationHelper.validateJson(requestStatus, RequestStatusJson));
        if (environment.production === false) {
          console.error(validation);
        }
        if (environment.production === true) {
          const error: ErrorMessageParameter = new ErrorMessageParameter();
          error.description = validation;
          this.errorMessageService.reportErrorMessage(error);
        }
        return previewImages;
      }
      requestStatus.data.forEach((previewImage) => {
        if (JsonValidationHelper.validateJson(previewImage, PreviewImageJson) !== true) {
          const validation = JSON.stringify(JsonValidationHelper.validateJson(requestStatus, RequestStatusJson));
          if (environment.production === false) {
            console.error(JsonValidationHelper.validateJson(previewImage, PreviewImageJson));
            console.error('Fehlerhaftes Vorschaubild "' + previewImage.name + '" mit ID: ' + previewImage.id);
          }
          if (environment.production === true) {
            const error: ErrorMessageParameter = new ErrorMessageParameter();
            error.description = validation;
            this.errorMessageService.reportErrorMessage(error);
          }
          return;
        }
        previewImages.unshift(previewImage);
      });

      return previewImages;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }

  public async getUserFile(user: User, previewImage: PreviewImage): Promise<File | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'User-Token': user.token,
        }),
        responseType: 'blob' as 'json',
      };

      const fileObservable = this.http.get<any>(
        environment.apiUrlUserFilesGetFile
          .replace('{{user_id}}', user.id.toString())
          .replace('{{preview_image_id}}', previewImage.id.toString()),
        options,
      );

      const file = await lastValueFrom(fileObservable);
      return file;
    } catch (error) {
      if (error.status === 401) {
        console.log('User-Token abgelaufen');
        return 401;
      }
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }
}
