<div class="ion-page" (click)="closeChatbotGreeting()">
  <ion-content [fullscreen]="true" scrollEvents="true" appResizeActions>
    <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid class="base-content-top-margin">
      <app-header-app></app-header-app>

      <app-menu>
      </app-menu>
      <app-menu-content class="app-menu-desktop"></app-menu-content>
      <div class="ion-page pointer-events-none" id="menu-button">
        <ion-row>
          <ion-col class="menu-column" size="1" offset-md="1">
            <ion-menu-button class="menu-button base-content-top-margin pointer-events-auto"></ion-menu-button>
          </ion-col>
        </ion-row>
      </div>
      <form [formGroup]="formGroup">
        <ion-row class="ion-margin-top">
          <ion-col offset="1" size="5" size-md="4" offset-md="2" class="display-flex">
            <ion-checkbox class="primary-checkbox"
              (ionChange)="changeAreTooltipsActive($any($event.target).checked)" formControlName="areTooltipsActive"
              labelPlacement="end"></ion-checkbox>
            <ion-label class="primary-checkbox-text" (click)="toggleAreTooltipsActive()">
              &nbsp;Chatbot-Helfer
            </ion-label>
          </ion-col>
          <ng-container *ngIf="isLoggedIn === false">
            <ion-col size="2" offset="1" size-md="2" offset-md="0" class="ion-justify-content-end display-flex">
              <a aria-label="zur Registrierung" href="#/tabs/registrierung" class="text-white">
                <strong>Registrierung</strong>
              </a>
            </ion-col>
            <ion-col size="2" size-md="2" class="ion-justify-content-end display-flex">
              <a aria-label="zum Login" href="#/tabs/login" class="text-white">
                <strong>Login</strong>
              </a>
            </ion-col>
          </ng-container>
        </ion-row>
      </form>
      <h1 class="ion-text-center text-white position-fixed welcome-text"><strong>Entdecke HUBGrade! <br />
          Deine App für Aus- und Weiterbildung! </strong></h1>
      <ion-row class="tab-bar-tooltip-row">
        <ion-col>
          <div class="tooltip-wrapper profil-tooltip"
            data-tooltip="Profil- und Dokumentenverwaltung (Funktion aktuell deaktiviert)" data-position="above">
          </div>
        </ion-col>
        <ion-col>
          <div class="tooltip-wrapper menu-tooltip" data-tooltip="Über das Menü kannst du die App navigieren"
            data-position="above">
          </div>
        </ion-col>
        <ion-col>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="ion-justify-content-center display-grid">
          <ion-img id="network-image-home" alt="Stapelgrafik"
            src="./../../../../assets/home-graphics/hubgrade-home-graphic.png"></ion-img>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>
