import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TooltipHelper } from 'app/helpers/tooltip-helper';

@Component({
  selector: 'app-home-navigation-bar',
  templateUrl: './home-navigation-bar.component.html',
  styleUrls: ['./home-navigation-bar.component.scss'],
})
export class HomeNavigationBarComponent implements OnInit, AfterViewInit {
  @Input() isLoggedIn = false;

  constructor(
    public router: Router,
    private modalCtrl: ModalController,
    private tooltipHelper: TooltipHelper,
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => this.tooltipHelper.menuToolTipBehavior(), 200);
  }

  public cancel() {
    return this.modalCtrl.dismiss(null, 'close');
  }
}
