<div class="modal-div" (click)="cancel($event)">
  <ion-card>
    <div class="ion-margin">
      <h5>
        <p><strong>{{objectToRemove}} {{action}}?</strong></p>
      </h5>
      <p>Bist du sicher, dass du {{objectToRemove}} aus {{locationWhereObjectIsToBeRemoved}} {{action}} willst?</p>
      <p>{{additionalInfo}}</p>
      <ion-row *ngIf="toggleQuestionText !== null" class="ion-margin-top">
        <ion-col size="11" class="ion-text-end">
          <ion-label class="highlighted-bold-text ion-margin-end">{{toggleQuestionText}}</ion-label>
        </ion-col>
        <ion-col size="1" class="display-flex justify-content-end">
          <ion-checkbox class="highlighted-checkbox" (ionChange)="changeIsToggled($any($event.target).checked)"
            labelPlacement="start"></ion-checkbox>
        </ion-col>
      </ion-row>
      <div class="ion-float-right ion-margin-bottom">
        <ion-buttons>
          <ion-row>
            <ion-col>
              <ion-button (click)="cancel($event)"><strong (click)="cancel($event)">behalten</strong></ion-button>
            </ion-col>
            <ion-col>
              <ion-button (click)="confirm()"
                class="background-color-bright-blue-2"><strong>{{action}}</strong></ion-button>
            </ion-col>
          </ion-row>
        </ion-buttons>
      </div>
    </div>
  </ion-card>
</div>
