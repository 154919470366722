<div class="ion-page">
  <ion-content [fullscreen]="true" scrollEvents="true" appResizeActions>
    <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid class="base-content-top-margin">
      <app-header-app></app-header-app>

      <app-menu>
      </app-menu>
      <app-menu-content class="app-menu-desktop"></app-menu-content>
      <div class="ion-page pointer-events-none" id="menu-button">
        <ion-row>
          <ion-col class="menu-column" size="1" offset-md="1">
            <ion-menu-button class="menu-button base-content-top-margin pointer-events-auto"></ion-menu-button>
          </ion-col>
        </ion-row>
      </div>

      <ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif" class="general-loading-animation"
        *ngIf="isLoading === true"></ion-img>
      <ion-row>
        <ion-col size-md="6" offset-md="3">
          <div class="base-content-top-margin center-element display-flex">
            <ion-card *ngIf="isLoggedIn === true">
              <div class="stretcher-div"></div>
              <ion-card-header class="ion-text-center">
                <h2>Du bist bereits eingeloggt.</h2>
              </ion-card-header>
              <ion-card-content>
                <ion-row>
                  <ion-col offset="1" size="10" size-md="6" offset-md="3">
                    <ion-button class="form-field" (click)="pressLogoutButton()" expand="block">
                      Ausloggen
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-card-content>
            </ion-card>
            <ion-card *ngIf="isLoggedIn === false">
              <div class="stretcher-div"></div>
              <ion-card-header class="ion-text-center">
                <h2>Login</h2>
              </ion-card-header>
              <ion-card-content class="ion-text-start">
                <form [formGroup]="formGroup">
                  <ion-row>
                    <ion-col size="12" size-md="6">
                      <ion-input (keydown.enter)="formHelper.nextInput(formGroup, $event, 'passwordInput')"
                        class="form-field" placeholder="Email" formControlName="emailInput"
                        (ionInput)="changeSelectedEmail($any($event.target).value)">
                      </ion-input>
                    </ion-col>
                    <ion-col size="12" size-md="6">
                      <ion-input (keydown.enter)="loginUser()" type="password" class="form-field" placeholder="Passwort"
                        formControlName="passwordInput" (ionInput)="changeSelectedPassword($any($event.target).value)">
                      </ion-input>
                    </ion-col>
                  </ion-row>

                  <ion-row>
                    <ion-col size="4" offset="8">
                      <ion-button class="form-field" (click)="pressLoginButton()" expand="block"
                        [disabled]="formGroup.valid === false || isLoading === true">
                        Login
                      </ion-button>
                    </ion-col>
                  </ion-row>

                  <ion-row>
                    <ion-col class="ion-text-end">
                      <a aria-label="zum Passwort zurücksetzen" href="{{passwordResetUrl}}{{ userLoginParameter.email ?
                      'email/' + userLoginParameter.email : '' }}">
                        Passwort vergessen?</a>
                    </ion-col>
                  </ion-row>

                  <ion-row *ngIf="errorCode === standardErrorCode">
                    <ion-col>
                      <ion-text class="form-error-text">
                        Uns ist ein Fehler unterlaufen. Bitte versuche es später erneut.
                      </ion-text>
                    </ion-col>
                  </ion-row>
                  <ion-row *ngIf="errorCode === userDataInputErrorCode">
                    <ion-col>
                      <ion-text class="form-error-text">
                        Ein Fehler ist aufgetreten. Bitte korrigiere deine Eingabe.
                      </ion-text>
                    </ion-col>
                  </ion-row>
                  <ion-row *ngIf="errorCode === 100">
                    <ion-col>
                      <ion-text class="form-error-text">
                        Zu deiner eingegebenen Email wurde kein Benutzer gefunden.
                      </ion-text>
                    </ion-col>
                  </ion-row>
                  <ion-row *ngIf="errorCode === 101">
                    <ion-col>
                      <ion-text class="form-error-text">
                        Das eingegebene Passwort ist nicht korrekt.
                      </ion-text>
                    </ion-col>
                  </ion-row>
                </form>
              </ion-card-content>
            </ion-card>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>
