<ion-grid>
  <app-chat-window [isChatOpen]="isChatOpen"></app-chat-window>
  <ion-row class="chatbot-bubble-row" (click)="greetingCloseButtonClick()">
    <ion-col size="11" size-md="10" class="display-grid" (click)="greetingCloseButtonClick()">
      <div id="chatbot-speech-bubble" (click)="$event.stopPropagation()">
        <ion-text>
          <ion-row>
            <ion-col class="margin-padding-0 ion-justify-content-end display-grid">
              <button id="greeting-close-button" class="close-button" (click)="greetingCloseButtonClick()">
                <ion-icon name="close"></ion-icon>
              </button>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="chatbot-greeting-message">
              Hallo, ich bin der Chatbot <i class="chatbot-greeting-icon icon-chatbot-outline icon-color-blue"></i> <br />
              Hubgrade wird übrigens gefördert von:
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size-sm="6" size="12">
              <ion-img src="../../../../assets/sponsors/logo-bibb.png"></ion-img>
            </ion-col>
            <ion-col size-sm="6" size="12">
              <ion-img src="../../../../assets/sponsors/logo-bmbf.png"></ion-img>
            </ion-col>
          </ion-row>
        </ion-text>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="10" class="display-flex justify-content-end">
      <ion-button (click)="toggleChat()" class="chatbot-md-button">
        <i class="chatbot-icon icon-chatbot-outline icon-color-dark-blue-1" id="chatbot-pulse" alt="chatbot-öffnen"></i>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>


<ion-tabs tabsHighlight="true">
  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="profil">
      <i class="tab-bar-icons icon-person-outline icon-color-dark-blue-1" alt="profil-öffnen"></i>
    </ion-tab-button>
    <ion-tab-button class="tab-button-nav" (click)="homeButtonClick()">
      <i id="tab-button-nav-image" class="tab-bar-icons icon-home-filled icon-color-dark-blue-1" alt="home-öffnen"></i>
    </ion-tab-button>
    <ion-tab-button class="visibility-hidden">
    </ion-tab-button>
    <ion-tab-button class="tab-button-chat" (click)="toggleChat()">
      <img class="tab-bar-icons chatbot-icon icon-chatbot-outline icon-color-dark-blue-1" id="chatbot-pulse" alt="chatbot-öffnen">
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
<ion-row class="footer-row">
  <ion-col size="6" size-md="5" offset-md="1" size-lg="4" offset-lg="2" class="ion-justify-content-start display-flex">
    Ein Projekt der ZWH e. V.
  </ion-col>
  <ion-col size="6" size-md="5" size-lg="4" class="ion-justify-content-end display-flex">
    <a href="#/tabs/impressum" aria-label="Impressum" class="text-white ion-margin-end">Impressum</a>
    <a href="#/tabs/datenschutz" aria-label="Datenschutz" class="text-white">Datenschutz</a>
  </ion-col>
</ion-row>
