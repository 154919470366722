import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { ChatWindowComponent } from 'app/components/layout/chat-window/chat-window.component';
import { BrowserInteractionHelper } from 'app/helpers/browser-interaction-helper';
import { CareerPathAlgorithmHelper } from 'app/helpers/career-path-algorithm-helper';
import { CareerPathHelper } from 'app/helpers/career-path-helper';
import { CourseHelper } from 'app/helpers/course-helper';
import { MatomoHelper } from 'app/helpers/matomo-helper';
import { StorageHelper } from 'app/helpers/storage-helper';
import { UserHelper } from 'app/helpers/user-helper';
import { MessageType } from 'app/models/chat-bot-message.model';
import { WATCH_LIST_LIMIT_CAREER_NOTIFICATION } from 'app/models/constants-app-texts';
import { WATCH_LIST_LIMIT } from 'app/models/constants-app-values';
import {
  FAVORITED_COURSE_TEMPLATE_TREE_BRANCHES,
  HAS_CAREER_SEARCHED_LOGICAL_NAME,
  UNFAVORITED_COURSE_TEMPLATE_TREE_BRANCHES,
} from 'app/models/constants-logical-names';
import { MATOMO_LOGICAL_NAME_CAREER_DISLIKED, MATOMO_LOGICAL_NAME_CAREER_LIKED } from 'app/models/constants-matomo';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { CourseTemplateTreeBranch } from 'app/models/course-template-tree-branch.model';
import { CourseTemplateTreeParameter } from 'app/models/course-template-tree-parameter.model';
import { Course } from 'app/models/course.model';
import { CourseParameter } from 'app/models/course_parameter.model';
import { QualificationCategory } from 'app/models/qualification-category.model';
import { StorableCourseTemplateTreeBranch } from 'app/models/storable-course-template-tree-branch.model';
import { StoredCourseTemplateTreeBranch } from 'app/models/stored-course-template-tree-branch.model';
import { Template } from 'app/models/template.model';
import { User } from 'app/models/user.model';
import { GetCoursesService } from 'app/services/get-courses.service';
import { UserWatchlistService } from 'app/services/user-watchlist.service';
import { QualificationType } from '../../../models/qualification-type.model';
import { CourseDetailsComponent } from '../course-details/course-details.component';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent implements OnInit, OnChanges {
  @ViewChild('thumbButtonRow', { read: ElementRef }) thumbButtonRow: ElementRef;
  @Output() changeIsSwiperCooldownEvent = new EventEmitter();
  @Input() courseTemplateTreeBranches: CourseTemplateTreeBranch[] = [];
  @Input() selectedRequirementQualificationType: QualificationType;
  @Input() selectedRequirementQualificationCategory: QualificationCategory;
  @Input() selectedTargetQualificationCategory: QualificationCategory;
  @Input() searchedCourseTemplateTreeParameter: CourseTemplateTreeParameter;
  @Input() isActiveTab = false;
  @Input() content: IonContent;
  @Input() amountOfPreviousCareerBranches: number = 0;

  public isPageLoading = false;
  public isCourseLoading = false;
  public areFavoritesLoading = false;
  //to access JSON in template
  public json = JSON;
  public currentCourseTemplateTreeBranch: CourseTemplateTreeBranch;
  public isLoggedIn = false;
  public isButtonCooldown = false;
  public isAtLikeLimit = false;
  public doesLikeButtonAddLike = false;
  public areSelectedCoursesLoading = true;
  public selectedCourseTemplateIds: number[] = [];
  public hasPotentialResultsInCareerSearch = false;
  public isDateSkipped = false;

  private hasRequestFailed = false;
  private favoritedCourseTemplateTreeBranches = [];
  private unfavoritedCourseTemplateTreeBranches: StorableCourseTemplateTreeBranch[] = [];
  private user: User = null;
  private errorCollection = '';

  constructor(
    public browserInteractionHelper: BrowserInteractionHelper,
    public careerPathAlgorithmHelper: CareerPathAlgorithmHelper,
    private careerPathHelper: CareerPathHelper,
    private chatWindowComponent: ChatWindowComponent,
    private courseHelper: CourseHelper,
    private courseService: GetCoursesService,
    private matomoHelper: MatomoHelper,
    private modalCtrl: ModalController,
    private storageHelper: StorageHelper,
    private userHelper: UserHelper,
    private userWatchlistService: UserWatchlistService,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    this.isLoggedIn = await this.userHelper.checkForUser();
    if (this.isLoggedIn === true) {
      this.user = await this.userHelper.loadUserData();
    }

    this.areFavoritesLoading = true;
    if (this.courseTemplateTreeBranches?.length <= 0) {
      this.hasPotentialResultsInCareerSearch = await this.careerPathHelper.hasPotentialResultsInCareerSearch(
        this.searchedCourseTemplateTreeParameter,
      );
    }
    // randomize a number between 3 and 7
    await this.loadFavoritedCourseTemplateTreeBranches();
    await this.loadSelectedCourses();
    await this.loadUnfavoritedCourseTemplateTreeBranches();
    this.markStamps(0);
    this.areFavoritesLoading = false;
    if (this.hasRequestFailed === true) {
      throw Error(this.errorCollection);
    }
  }

  async ngOnChanges() {
    await this.careerPathAlgorithmHelper.createParametersForCurrentTree(this.courseTemplateTreeBranches[0]?.id);
    this.isLoggedIn = await this.userHelper.checkForUser();
    if (this.isLoggedIn === true) {
      this.user = await this.userHelper.loadUserData();
    }

    this.areFavoritesLoading = true;
    await this.loadFavoritedCourseTemplateTreeBranches();
    await this.loadSelectedCourses();
    await this.loadUnfavoritedCourseTemplateTreeBranches();
    this.areFavoritesLoading = false;
    if (this.hasRequestFailed === true) {
      throw Error(this.errorCollection);
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  public courseClicked(courseId: number, event: Event): void {
    event.stopPropagation();
    this.showCourseDetailsFromTreeBranch(courseId);
  }

  public updateBranches(branches: CourseTemplateTreeBranch[]): void {
    this.courseTemplateTreeBranches = branches;
  }

  public setCurrentBranch(index: number): void {
    this.currentCourseTemplateTreeBranch = this.courseTemplateTreeBranches[index];
  }

  public async markStamps(index: number): Promise<void> {
    const branches: CourseTemplateTreeBranch[] = [];
    if (index !== 0) {
      branches.push(this.courseTemplateTreeBranches[index - 1]);
    }
    branches.push(this.courseTemplateTreeBranches[index]);
    if (index < this.courseTemplateTreeBranches.length - 1) {
      branches.push(this.courseTemplateTreeBranches[index + 1]);
    }

    if (
      this.unfavoritedCourseTemplateTreeBranches.length !== 0 ||
      this.favoritedCourseTemplateTreeBranches.length !== 0
    ) {
      for (const branch of branches) {
        const storableBranch = this.courseTemplateTreeBranchToStorableCourseTemplateTreeBranch(branch);
        if (this.isBranchLikesStored(storableBranch) === true) {
          const stamp = document.getElementById(JSON.stringify(branch) + '-stamp');
          if (stamp === null) {
            continue;
          }
          this.likeCareerPath(stamp);
        }
        if (this.isBranchDislikesStored(storableBranch) === true) {
          const stamp = document.getElementById(JSON.stringify(branch) + '-stamp');
          if (stamp === null) {
            continue;
          }
          this.dislikeCareerPath(stamp);
        }
        this.changeDetector.detectChanges();
      }
    }
  }

  public async clickLikeButton(): Promise<void> {
    this.isButtonCooldown = true;
    const favoritedCourseTemplateTreeBranch = this.courseTemplateTreeBranchToStorableCourseTemplateTreeBranch(
      this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch,
    );
    if (this.isBranchLikesStored(favoritedCourseTemplateTreeBranch) === true) {
      await this.removeFromLikesStorage();
      const stamp = document.getElementById(JSON.stringify(this.currentCourseTemplateTreeBranch) + '-stamp');
      stamp.classList.remove('like-stamp');
      setTimeout(() => (this.isButtonCooldown = false), 1000);
      return;
    }
    if (this.isBranchDislikesStored(favoritedCourseTemplateTreeBranch) === true) {
      await this.careerPathAlgorithmHelper.removeDislikeFromAlgorithmParameters(
        (this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch).id,
      );
    }

    await this.careerPathAlgorithmHelper.addAmountOfExtraDislikes(
      (this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch).id,
    );
    await this.playLikeAnimation();
    setTimeout(() => (this.isButtonCooldown = false), 1000);
  }

  public async clickDislikeButton(): Promise<void> {
    this.isButtonCooldown = true;
    const unfavoritedCourseTemplateTreeBranch = this.courseTemplateTreeBranchToStorableCourseTemplateTreeBranch(
      this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch,
    );
    if (this.isBranchDislikesStored(unfavoritedCourseTemplateTreeBranch) === true) {
      await this.careerPathAlgorithmHelper.removeDislikeFromAlgorithmParameters(
        (this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch).id,
      );
      await this.removeFromDislikesStorage();
      const stamp = document.getElementById(JSON.stringify(this.currentCourseTemplateTreeBranch) + '-stamp');
      stamp.classList.remove('dislike-stamp');
      setTimeout(() => (this.isButtonCooldown = false), 1000);
      return;
    }

    this.isDateSkipped = await this.careerPathAlgorithmHelper.addDislikeToAlgorithmParameters(
      this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch,
    );
    await this.playDislikeAnimation();
    setTimeout(() => (this.isButtonCooldown = false), 1000);
  }

  public async doubleClickToggleFavorite(): Promise<void> {
    if (this.browserInteractionHelper.isDoubleClick() === true && this.isLikingDisabled() === false) {
      this.clickLikeButton();
    }
  }

  public isLikingDisabled(): boolean {
    return this.isButtonCooldown || (this.checkIfLikeClickAddsLike() && this.isAtLikeLimit);
  }

  public setHasSearched(hasSearched: boolean): void {
    this.storageHelper.setSessionStorage(HAS_CAREER_SEARCHED_LOGICAL_NAME, hasSearched);
  }

  public async showCourseDetailsFromTreeBranch(branchTemplateId: number): Promise<void> {
    const courseParameter = new CourseParameter();
    courseParameter.courseTemplateIds = [branchTemplateId];
    courseParameter.partitionInstituteTemplateCount = 3;

    this.isCourseLoading = true;
    this.changeDetector.detectChanges();

    const coursesResult = await this.courseService.searchCourses(courseParameter);

    if (coursesResult === SYSTEM_ERROR_CODE) {
      this.isCourseLoading = false;
      throw Error(this.courseService.errorMessage);
    }

    this.isCourseLoading = false;

    const courseTemplates = this.courseHelper.getCourseTemplates(coursesResult as Course[]);

    const modal = await this.modalCtrl.create({
      component: CourseDetailsComponent,
      cssClass: 'small-modal',
      componentProps: {
        courseTemplate: courseTemplates[0],
        courses: coursesResult,
        user: this.user,
        areSelectedCoursesLoading: this.areSelectedCoursesLoading,
        selectedCourseTemplateIds: this.selectedCourseTemplateIds,
        enableBackdropDismiss: true,
      },
    });
    modal.present();

    const { role } = await modal.onWillDismiss();

    if (role === 'cancel') {
      await this.careerPathAlgorithmHelper.addAmountOfExtraDislikes(
        (this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch).id,
      );
      this.loadSelectedCourses();
    }
  }

  public setDoesLikeClickAddLike(): void {
    this.doesLikeButtonAddLike = this.checkIfLikeClickAddsLike();
  }

  private async playLikeAnimation(): Promise<void> {
    const stamp = document.getElementById(JSON.stringify(this.currentCourseTemplateTreeBranch) + '-stamp');

    stamp.style.transform = '';
    await this.removeFromDislikesStorage();
    const wasAddedToLikes = await this.addToLikesStorage();
    if (wasAddedToLikes === false) {
      return;
    }

    this.addLikeStamp(stamp);

    setTimeout(() => {
      stamp.style.transition = 'transform 200ms ease-in-out';
    }, 200);

    setTimeout(() => {
      stamp.style.transform = 'scale(1) rotate(350deg)';
    }, 200);
    setTimeout(() => {
      stamp.style.transition = 'none';
    }, 400);
  }

  private async playDislikeAnimation(): Promise<void> {
    const stamp = document.getElementById(JSON.stringify(this.currentCourseTemplateTreeBranch) + '-stamp');

    stamp.style.transform = '';
    await this.removeFromLikesStorage();
    await this.addToDisikesStorage();
    this.addDislikeStamp(stamp);

    setTimeout(() => {
      stamp.style.transition = 'transform 200ms ease-in-out';
    }, 200);

    setTimeout(() => {
      stamp.style.transform = 'scale(1) rotate(190deg)';
    }, 200);
    setTimeout(() => {
      stamp.style.transition = 'none';
    }, 400);
  }

  private likeCareerPath(element: HTMLElement): void {
    this.addLikeStamp(element);
    element.style.transform = 'scale(1) rotate(350deg)';
  }

  private dislikeCareerPath(element: HTMLElement): void {
    this.addDislikeStamp(element);
    element.style.transform = 'scale(1) rotate(190deg)';
  }

  private addLikeStamp(element: HTMLElement): void {
    element?.classList.remove('dislike-stamp');
    element?.classList.add('like-stamp');
  }

  private addDislikeStamp(element: HTMLElement): void {
    element?.classList.remove('like-stamp');
    element?.classList.add('dislike-stamp');
  }

  private async loadFavoritedCourseTemplateTreeBranches(): Promise<void> {
    if (this.isLoggedIn === false) {
      const favoritedCourseTemplateTreeBranches = await this.storageHelper.getPreferences(
        FAVORITED_COURSE_TEMPLATE_TREE_BRANCHES,
      );
      if (favoritedCourseTemplateTreeBranches !== null) {
        this.favoritedCourseTemplateTreeBranches = favoritedCourseTemplateTreeBranches;
      }
      return;
    }
    const favoritedCourseTemplateTreeBranches = await this.userWatchlistService.loadStoredCourseTemplateTreeBranches(
      this.user,
    );

    if (favoritedCourseTemplateTreeBranches === 401) {
      this.isLoggedIn = this.userHelper.logoutUser();
      return;
    }
    if (favoritedCourseTemplateTreeBranches === SYSTEM_ERROR_CODE) {
      this.hasRequestFailed = true;
      this.errorCollection += '\n' + this.userWatchlistService.errorMessage;
      return;
    }

    if (favoritedCourseTemplateTreeBranches !== null) {
      this.favoritedCourseTemplateTreeBranches =
        favoritedCourseTemplateTreeBranches as StoredCourseTemplateTreeBranch[];
    }

    this.isAtLikeLimit = this.checkIfLikesAreAtLimit();
  }

  private async loadUnfavoritedCourseTemplateTreeBranches(): Promise<void> {
    const unfavoritedCourseTemplateTreeBranches = await this.storageHelper.getPreferences(
      UNFAVORITED_COURSE_TEMPLATE_TREE_BRANCHES,
    );
    if (unfavoritedCourseTemplateTreeBranches !== null) {
      this.unfavoritedCourseTemplateTreeBranches = unfavoritedCourseTemplateTreeBranches;
    }
  }

  private isBranchLikesStored(favoritedCourseTemplateTreeBranch: StorableCourseTemplateTreeBranch): boolean {
    if (this.isLoggedIn === false) {
      const index = this.favoritedCourseTemplateTreeBranches.findIndex(
        (branch) =>
          JSON.stringify(branch.templateIds) === JSON.stringify(favoritedCourseTemplateTreeBranch.templateIds),
      );

      if (index === -1) {
        return false;
      }
      return true;
    }

    const index = this.courseHelper
      .storedToStorableCourseTemplateTreeBranches(this.favoritedCourseTemplateTreeBranches)
      .findIndex(
        (branch) =>
          JSON.stringify(branch.templateIds) === JSON.stringify(favoritedCourseTemplateTreeBranch.templateIds),
      );

    if (index === -1) {
      return false;
    }
    return true;
  }

  private isBranchDislikesStored(unfavoritedCourseTemplateTreeBranch: StorableCourseTemplateTreeBranch): boolean {
    const index = this.unfavoritedCourseTemplateTreeBranches.findIndex(
      (branch) =>
        JSON.stringify(branch.templateIds) === JSON.stringify(unfavoritedCourseTemplateTreeBranch.templateIds),
    );

    if (index === -1) {
      return false;
    }
    return true;
  }

  private async addToLikesStorage(): Promise<boolean> {
    this.matomoHelper.trackMatomo(MATOMO_LOGICAL_NAME_CAREER_LIKED, [
      {
        courseTemplateId: this.currentCourseTemplateTreeBranch.id,
        courseTemplateName: this.currentCourseTemplateTreeBranch.name,
      },
      this.currentCourseTemplateTreeBranch.nextBranch === undefined
        ? ''
        : {
            courseTemplateId: this.currentCourseTemplateTreeBranch.nextBranch.id,
            courseTemplateName: this.currentCourseTemplateTreeBranch.nextBranch.name,
          },
    ]);
    if (this.isLoggedIn === false) {
      const favoritedCourseTemplateTreeBranch = this.courseTemplateTreeBranchToStorableCourseTemplateTreeBranch(
        this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch,
      );

      if (this.isBranchDislikesStored(favoritedCourseTemplateTreeBranch) === false) {
        this.favoritedCourseTemplateTreeBranches.push(structuredClone(favoritedCourseTemplateTreeBranch));
        await this.storageHelper.setPreferences(
          FAVORITED_COURSE_TEMPLATE_TREE_BRANCHES,
          this.favoritedCourseTemplateTreeBranches,
        );
      }
      return;
    }

    const favoritedCourseTemplateTreeBranch = this.courseTemplateTreeBranchToStorableCourseTemplateTreeBranch(
      this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch,
    );

    if (this.isBranchLikesStored(favoritedCourseTemplateTreeBranch) === false) {
      const wasAddedToLikes = await this.courseHelper.storeCourseTemplateTree(this.user, [
        favoritedCourseTemplateTreeBranch,
      ]);
      if (wasAddedToLikes === false) {
        return false;
      }

      await this.loadFavoritedCourseTemplateTreeBranches();
    }
    this.isAtLikeLimit = this.checkIfLikesAreAtLimit();
    return true;
  }

  private async addToDisikesStorage(): Promise<void> {
    this.matomoHelper.trackMatomo(MATOMO_LOGICAL_NAME_CAREER_DISLIKED, [
      {
        courseTemplateId: this.currentCourseTemplateTreeBranch.id,
        courseTemplateName: this.currentCourseTemplateTreeBranch.name,
      },
      this.currentCourseTemplateTreeBranch?.hasOwnProperty('nextBranch') === false
        ? ''
        : {
            courseTemplateId: this.currentCourseTemplateTreeBranch.nextBranch.id,
            courseTemplateName: this.currentCourseTemplateTreeBranch.nextBranch.name,
          },
    ]);
    const unfavoritedCourseTemplateTreeBranch = this.courseTemplateTreeBranchToStorableCourseTemplateTreeBranch(
      this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch,
    );

    if (this.isBranchDislikesStored(unfavoritedCourseTemplateTreeBranch) === false) {
      this.unfavoritedCourseTemplateTreeBranches.push(structuredClone(unfavoritedCourseTemplateTreeBranch));
      await this.storageHelper.setPreferences(
        UNFAVORITED_COURSE_TEMPLATE_TREE_BRANCHES,
        this.unfavoritedCourseTemplateTreeBranches,
      );
    }
  }

  private async removeFromLikesStorage(): Promise<void> {
    if (this.isLoggedIn === false) {
      const favoritedCourseTemplateTreeBranch = new StorableCourseTemplateTreeBranch();
      favoritedCourseTemplateTreeBranch.templateIds = [];
      favoritedCourseTemplateTreeBranch.templateIds.push(
        (this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch).id,
      );
      if ((this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch).nextBranch !== undefined) {
        favoritedCourseTemplateTreeBranch.templateIds.push(
          (this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch).nextBranch.id,
        );
      }

      const indexToDelete = this.favoritedCourseTemplateTreeBranches.findIndex(
        (branch) =>
          JSON.stringify(branch.templateIds) === JSON.stringify(favoritedCourseTemplateTreeBranch.templateIds),
      );

      if (indexToDelete === -1) {
        return;
      }

      this.favoritedCourseTemplateTreeBranches.splice(indexToDelete, 1);
      await this.storageHelper.setPreferences(
        FAVORITED_COURSE_TEMPLATE_TREE_BRANCHES,
        this.favoritedCourseTemplateTreeBranches,
      );
      return;
    }

    const favoritedCourseTemplateTreeBranch = new StorableCourseTemplateTreeBranch();
    favoritedCourseTemplateTreeBranch.templateIds = [];
    favoritedCourseTemplateTreeBranch.templateIds.push(
      (this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch).id,
    );
    if ((this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch).nextBranch !== undefined) {
      favoritedCourseTemplateTreeBranch.templateIds.push(
        (this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch).nextBranch.id,
      );
    }

    const indexToDelete = this.courseHelper
      .storedToStorableCourseTemplateTreeBranches(
        this.favoritedCourseTemplateTreeBranches as StoredCourseTemplateTreeBranch[],
      )
      .findIndex(
        (branch) =>
          JSON.stringify(branch.templateIds) === JSON.stringify(favoritedCourseTemplateTreeBranch.templateIds),
      );

    if (indexToDelete === -1) {
      return;
    }

    await this.userWatchlistService.deleteStoredCourseTemplateTreeBranch(
      this.user,
      (this.favoritedCourseTemplateTreeBranches[indexToDelete] as StoredCourseTemplateTreeBranch).id,
    );
    this.favoritedCourseTemplateTreeBranches.splice(indexToDelete, 1);

    this.isAtLikeLimit = this.checkIfLikesAreAtLimit();
  }

  private async removeFromDislikesStorage(): Promise<void> {
    const unFavoritedCourseTemplateTreeBranch = new StorableCourseTemplateTreeBranch();
    unFavoritedCourseTemplateTreeBranch.templateIds = [];
    unFavoritedCourseTemplateTreeBranch.templateIds.push(
      (this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch).id,
    );
    if ((this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch).nextBranch !== undefined) {
      unFavoritedCourseTemplateTreeBranch.templateIds.push(
        (this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch).nextBranch.id,
      );
    }

    const indexToDelete = this.unfavoritedCourseTemplateTreeBranches.findIndex(
      (branch) =>
        JSON.stringify(branch.templateIds) === JSON.stringify(unFavoritedCourseTemplateTreeBranch.templateIds),
    );

    if (indexToDelete === -1) {
      return;
    }

    this.unfavoritedCourseTemplateTreeBranches.splice(indexToDelete, 1);
    await this.storageHelper.setPreferences(
      UNFAVORITED_COURSE_TEMPLATE_TREE_BRANCHES,
      this.unfavoritedCourseTemplateTreeBranches,
    );
  }

  private courseTemplateTreeBranchToStorableCourseTemplateTreeBranch(
    courseTemplateTreeBranch: CourseTemplateTreeBranch,
  ): StorableCourseTemplateTreeBranch {
    if (courseTemplateTreeBranch === undefined) {
      return;
    }
    const storedCourseTemplateTreeBranch = new StorableCourseTemplateTreeBranch();
    storedCourseTemplateTreeBranch.countryWide = this.searchedCourseTemplateTreeParameter.countryWide;
    storedCourseTemplateTreeBranch.distance = this.searchedCourseTemplateTreeParameter.distance;
    storedCourseTemplateTreeBranch.zip = this.searchedCourseTemplateTreeParameter.zip;
    storedCourseTemplateTreeBranch.requirementQualificationCategoryId =
      this.selectedRequirementQualificationCategory?.id;
    storedCourseTemplateTreeBranch.requirementQualificationTypeId = this.selectedRequirementQualificationType.id;
    storedCourseTemplateTreeBranch.targetQualificationCategoryId = this.selectedTargetQualificationCategory?.id;
    storedCourseTemplateTreeBranch.templateIds = [];
    storedCourseTemplateTreeBranch.templateIds.push(courseTemplateTreeBranch.id);
    if (courseTemplateTreeBranch.nextBranch !== undefined) {
      storedCourseTemplateTreeBranch.templateIds.push(courseTemplateTreeBranch.nextBranch.id);
    }

    return storedCourseTemplateTreeBranch;
  }

  private checkIfLikeClickAddsLike(): boolean {
    if (
      this.isBranchLikesStored(
        this.courseTemplateTreeBranchToStorableCourseTemplateTreeBranch(
          this.currentCourseTemplateTreeBranch as CourseTemplateTreeBranch,
        ),
      )
    ) {
      return false;
    } else {
      return true;
    }
  }

  private checkIfLikesAreAtLimit(): boolean {
    if (this.favoritedCourseTemplateTreeBranches.length >= WATCH_LIST_LIMIT) {
      this.activateChatbotWatchListLimitNotification();
      return true;
    }
    if (this.favoritedCourseTemplateTreeBranches.length < WATCH_LIST_LIMIT) {
      return false;
    }
  }

  private activateChatbotWatchListLimitNotification() {
    this.chatWindowComponent.receiveSystemMessage({
      text: WATCH_LIST_LIMIT_CAREER_NOTIFICATION,
      messageType: MessageType.SystemMessage,
    });
  }

  private async loadSelectedCourses(): Promise<void> {
    this.areSelectedCoursesLoading = true;

    const selectedCoursesPreferenceResult = await this.userWatchlistService.loadStoredCourses(this.user);

    if (selectedCoursesPreferenceResult === 401) {
      this.areSelectedCoursesLoading = false;
      this.isLoggedIn = this.userHelper.logoutUser();
      return;
    }
    if (selectedCoursesPreferenceResult === SYSTEM_ERROR_CODE) {
      this.areSelectedCoursesLoading = false;
      throw Error(this.userWatchlistService.errorMessage);
    }

    const selectedCourseTemplateIds = [];

    for (const selectedCourseTemplate of selectedCoursesPreferenceResult as Template[]) {
      selectedCourseTemplateIds.push(selectedCourseTemplate.id);
    }
    this.selectedCourseTemplateIds = selectedCourseTemplateIds;

    this.isAtLikeLimit = this.checkIfLikesAreAtLimit();
    this.areSelectedCoursesLoading = false;
  }
}
