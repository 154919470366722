import { Injectable } from '@angular/core';
import { StorageHelper } from 'app/helpers/storage-helper';
import { ARE_TOOLTIPS_ACTIVE, SHOWN_SESSION_TOOLTIPS } from 'app/models/constants-logical-names';

@Injectable()
export class TooltipHelper {
  constructor(private storageHelper: StorageHelper) {}
  public async courseSearchTooltipBehavior(): Promise<void> {
    if ((await this.storageHelper.getPreferences(ARE_TOOLTIPS_ACTIVE)) === false) {
      return;
    }
    const startCourseSearchTooltip = document.getElementsByClassName('start-course-search-tooltip');
    this.appearTooltip(startCourseSearchTooltip);
    setTimeout(() => {
      this.disappearTooltip(startCourseSearchTooltip);
    }, 5000);
  }

  public disappearAllCourseSearchTooltips(): void {
    const startCourseSearchTooltip = document.getElementsByClassName('start-course-search-tooltip');
    this.disappearTooltip(startCourseSearchTooltip);
  }

  public async careerSearchTooltipBehavior(): Promise<void> {
    if ((await this.storageHelper.getPreferences(ARE_TOOLTIPS_ACTIVE)) === false) {
      return;
    }
    const startCareerSearchTooltip = document.getElementsByClassName('start-career-search-tooltip');
    this.appearTooltip(startCareerSearchTooltip);
    setTimeout(() => {
      this.disappearTooltip(startCareerSearchTooltip);
    }, 5000);
  }

  public disappearAllCareerSearchTooltips(): void {
    const startCareerSearchTooltip = document.getElementsByClassName('start-career-search-tooltip');
    this.disappearTooltip(startCareerSearchTooltip);
  }

  public async homeToolTipBehavior(): Promise<void> {
    if ((await this.storageHelper.getPreferences(ARE_TOOLTIPS_ACTIVE)) === false) {
      this.disappearAllHomeTooltips();
      return;
    }
    const profileTooltip = document.getElementsByClassName('profil-tooltip');
    const menuTooltip = document.getElementsByClassName('menu-tooltip');
    this.appearTooltip(menuTooltip);
    setTimeout(() => {
      this.disappearTooltip(menuTooltip);
    }, 5000);
    setTimeout(async () => {
      if ((await this.storageHelper.getPreferences(ARE_TOOLTIPS_ACTIVE)) === false) {
        return;
      }
      this.appearTooltip(profileTooltip);
      setTimeout(() => {
        this.disappearTooltip(profileTooltip);
      }, 5000);
    }, 5000);
  }

  public disappearAllHomeTooltips(): void {
    const profileTooltip = document.getElementsByClassName('profil-tooltip');
    const menuTooltip = document.getElementsByClassName('menu-tooltip');
    this.disappearTooltip(menuTooltip);
    this.disappearTooltip(profileTooltip);
  }

  public async menuToolTipBehavior(): Promise<void> {
    if ((await this.storageHelper.getPreferences(ARE_TOOLTIPS_ACTIVE)) === false) {
      return;
    }
    const courseSearchTooltip = document.getElementsByClassName('course-search-tooltip');
    const watchListTooltip = document.getElementsByClassName('watch-list-tooltip');
    const careerSearchTooltip = document.getElementsByClassName('career-search-tooltip');
    this.appearTooltip(careerSearchTooltip);
    setTimeout(async () => {
      this.disappearTooltip(careerSearchTooltip);
      if ((await this.storageHelper.getPreferences(ARE_TOOLTIPS_ACTIVE)) === false) {
        return;
      }
      this.appearTooltip(watchListTooltip);
      setTimeout(async () => {
        this.disappearTooltip(watchListTooltip);
        if ((await this.storageHelper.getPreferences(ARE_TOOLTIPS_ACTIVE)) === false) {
          return;
        }
        this.appearTooltip(courseSearchTooltip);
        setTimeout(() => {
          this.disappearTooltip(courseSearchTooltip);
        }, 5000);
      }, 5000);
    }, 5000);
  }

  public disappearAllMenuTooltips(): void {
    const courseSearchTooltip = document.getElementsByClassName('course-search-tooltip');
    const watchListTooltip = document.getElementsByClassName('watch-list-tooltip');
    const careerSearchTooltip = document.getElementsByClassName('career-search-tooltip');
    this.disappearTooltip(courseSearchTooltip);
    this.disappearTooltip(watchListTooltip);
    this.disappearTooltip(careerSearchTooltip);
  }

  private appearTooltip(tooltip: HTMLCollectionOf<Element>): void {
    const shownSessionTooltips: string[] =
      this.storageHelper.getSessionStorage(SHOWN_SESSION_TOOLTIPS) === null
        ? []
        : this.storageHelper.getSessionStorage(SHOWN_SESSION_TOOLTIPS);
    if (shownSessionTooltips.includes(tooltip[0]?.classList[1])) {
      return;
    }
    for (let i = 0; i < tooltip.length; i++) {
      tooltip[i].classList.add('visible');
      if (!shownSessionTooltips.includes(tooltip[i].classList[1])) {
        shownSessionTooltips.push(tooltip[i].classList[1]);
        this.storageHelper.setSessionStorage(SHOWN_SESSION_TOOLTIPS, shownSessionTooltips);
      }
    }
  }

  private disappearTooltip(tooltip: HTMLCollectionOf<Element>): void {
    for (let i = 0; i < tooltip.length; i++) {
      tooltip[i].classList.remove('visible');
    }
  }
}
