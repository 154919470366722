import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Gesture, GestureController, GestureDetail, IonContent } from '@ionic/angular';
import { BrowserInteractionHelper } from 'app/helpers/browser-interaction-helper';
import { CareerPathAlgorithmHelper } from 'app/helpers/career-path-algorithm-helper';
import { CareerPathHelper } from 'app/helpers/career-path-helper';
import { CourseHelper } from 'app/helpers/course-helper';
import { JsonValidationHelper } from 'app/helpers/json-validation-helper';
import { StorageHelper } from 'app/helpers/storage-helper';
import { HAS_CAREER_SEARCHED_LOGICAL_NAME } from 'app/models/constants-logical-names';
import { STANDARD_ERROR_CODE, SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { CourseTemplateTreeBranch } from 'app/models/course-template-tree-branch.model';
import { CourseTemplateTreeParameter } from 'app/models/course-template-tree-parameter.model';
import { CourseTemplateTree } from 'app/models/course-template-tree.model';
import { QualificationCategory } from 'app/models/qualification-category.model';
import { QualificationType } from 'app/models/qualification-type.model';
import CourseTemplateTreeParameterJson from '../../../validators/course_template_tree_parameter_v1-0-0.json';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss'],
})
export class SwiperComponent implements OnInit, OnChanges {
  @Output() changeIndexEvent = new EventEmitter();
  @Output() changeIsSwiperCooldownEvent = new EventEmitter();
  @Output() courseClickedEvent = new EventEmitter();
  @Output() doubleClickToggleFavorite = new EventEmitter();
  @Output() isDateSkippedDoneEvent = new EventEmitter();
  @Output() setDoesLikeClickAddLikeEvent = new EventEmitter();
  @Output() updateBranchesEvent = new EventEmitter();
  @Input() amountOfPreviousCareerBranches: number = 0;
  @Input() content: IonContent;
  @Input() courseTemplateTreeBranches: CourseTemplateTreeBranch[] = [];
  @Input() doesLikeButtonAddLike: boolean = false;
  @Input() isCourseLoading: boolean = false;
  @Input() isDateSkipped: boolean = false;
  @Input() searchedCourseTemplateTreeParameter: CourseTemplateTreeParameter;
  @Input() selectedRequirementQualificationCategory: QualificationCategory;
  @Input() selectedRequirementQualificationType: QualificationType;

  public currentCourseTemplateTreeBranchIndex: number;
  public isLoading: boolean = false;
  public isSwiperCooldown: boolean = false;
  public json = JSON;
  public renderedCourseTemplateTreeBranches: CourseTemplateTreeBranch[] = [];

  private loadedTreesInLoadingCycle: number = 0;

  constructor(
    public browserInteractionHelper: BrowserInteractionHelper,
    public careerPathAlgorithmHelper: CareerPathAlgorithmHelper,
    private careerPathHelper: CareerPathHelper,
    private courseHelper: CourseHelper,
    private gestureCtrl: GestureController,
    private storageHelper: StorageHelper,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    if (this.courseTemplateTreeBranches.length === 1 || this.loadedTreesInLoadingCycle === 0) {
      await this.careerPathAlgorithmHelper.addDateSkippedToAlgorithmParameters(
        this.courseTemplateTreeBranches[this.courseTemplateTreeBranches.length - 1]?.id,
      );
      await this.loadMoreBranches();
      this.changeDetector.detectChanges();
    }
    this.setupSwipeGesture();
    await this.updateRenderedBranches();
    this.changeDetector.detectChanges();
    await this.activateCurrentSlide();
  }

  async ngOnChanges() {
    if (this.isDateSkipped === true) {
      this.isDateSkipped = false;
      this.isDateSkippedDoneEvent.emit();
      await this.loadMoreBranches();
    }
  }

  public courseClicked(courseId: number, event: Event): void {
    if (this.isCourseLoading === true) {
      return;
    }
    this.courseClickedEvent.emit({ courseId, event });
  }

  private async updateRenderedBranches(): Promise<void> {
    if (this.currentCourseTemplateTreeBranchIndex === undefined) {
      this.currentCourseTemplateTreeBranchIndex = 0;
      this.changeIndexEvent.emit({ index: this.currentCourseTemplateTreeBranchIndex });

      this.renderedCourseTemplateTreeBranches.push(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex],
      );
      this.renderedCourseTemplateTreeBranches.push(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex + 1],
      );

      this.setDoesLikeClickAddLikeEvent.emit();
      return;
    }
    if (this.renderedCourseTemplateTreeBranches.length === 2) {
      this.renderedCourseTemplateTreeBranches.push(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex + 1],
      );
    }
  }

  private setupSwipeGesture(): void {
    const swiperContainer = document.querySelector('.swiper-container');

    const gesture: Gesture = this.gestureCtrl.create({
      el: swiperContainer,
      gestureName: 'swipe',
      direction: 'x',
      threshold: 0,
      onMove: (event) => this.onSwipeStart(event),
      onEnd: (event) => this.onSwipeEnd(event),
    });

    gesture.enable();
  }

  private onSwipeStart(event: GestureDetail): void {
    this.changeDetector.detectChanges();
    if (
      !document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[0])) ||
      this.isSwiperCooldown === true
    ) {
      return;
    }
    this.changeDetector.detectChanges();
    document.getElementById(
      'swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[0]),
    ).style.marginLeft = event.deltaX + 'px';
  }

  private onSwipeEnd(event: GestureDetail): void {
    this.resetMarginsOfBranchElement(
      document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[0])),
    );
    this.resetMarginsOfBranchElement(
      document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[1])),
    );
    this.resetMarginsOfBranchElement(
      document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[2])),
    );
    if (event.deltaX >= 150) {
      this.isSwiperCooldown = true;
      this.changeIsSwiperCooldownEvent.emit({ isSwiperCooldown: this.isSwiperCooldown });
      this.resetMarginsOfBranchElement(
        document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[0])),
      );
      this.goToPreviousSlide().then(() => {
        setTimeout(() => {
          this.isSwiperCooldown = false;
          this.changeIsSwiperCooldownEvent.emit({ isSwiperCooldown: this.isSwiperCooldown });
          this.changeDetector.detectChanges();
        }, 300);
      });
    }
    if (event.deltaX <= -150) {
      this.isSwiperCooldown = true;
      this.changeIsSwiperCooldownEvent.emit({ isSwiperCooldown: this.isSwiperCooldown });
      this.goToNextSlide().then(() => {
        setTimeout(() => {
          this.isSwiperCooldown = false;
          this.changeIsSwiperCooldownEvent.emit({ isSwiperCooldown: this.isSwiperCooldown });
          this.changeDetector.detectChanges();
        }, 300);
      });
    }
  }

  private async goToNextSlide(): Promise<void> {
    if (
      this.currentCourseTemplateTreeBranchIndex >= this.courseTemplateTreeBranches.length - 2 &&
      this.isLoading === false
    ) {
      await this.loadMoreBranches();
      await this.updateRenderedBranches();
      this.resetMarginsOfBranchElement(
        document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[0])),
      );
      this.resetMarginsOfBranchElement(
        document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[1])),
      );
      this.resetMarginsOfBranchElement(
        document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[2])),
      );
      this.changeDetector.detectChanges();
    }

    if (this.currentCourseTemplateTreeBranchIndex >= this.courseTemplateTreeBranches.length - 1) {
      return;
    }
    this.currentCourseTemplateTreeBranchIndex++;

    if (this.hasPreviousTreeRootChanged() === true) {
      await this.careerPathAlgorithmHelper.addDateSkippedToAlgorithmParameters(
        this.renderedCourseTemplateTreeBranches[0].id,
      );

      if ((await this.careerPathAlgorithmHelper.doesHavePreLoadedTrees()) === false) {
        this.loadMoreBranches();
      }
    }
    if (this.currentCourseTemplateTreeBranchIndex === 1) {
      this.renderedCourseTemplateTreeBranches.push(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex + 1],
      );
      this.changeDetector.detectChanges();
      this.activateCurrentSlide();
      this.changeIndexEvent.emit({ index: this.currentCourseTemplateTreeBranchIndex });
      this.resetMarginsOfBranchElement(
        document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[0])),
      );
      this.resetMarginsOfBranchElement(
        document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[1])),
      );
      this.resetMarginsOfBranchElement(
        document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[2])),
      );
      return;
    }

    this.renderedCourseTemplateTreeBranches.shift();
    if (this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex + 1] !== undefined) {
      this.renderedCourseTemplateTreeBranches.push(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex + 1],
      );
      this.changeDetector.detectChanges();
    }
    this.activateCurrentSlide();
    this.changeIndexEvent.emit({ index: this.currentCourseTemplateTreeBranchIndex });
    this.resetMarginsOfBranchElement(
      document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[0])),
    );
    this.resetMarginsOfBranchElement(
      document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[1])),
    );
    this.resetMarginsOfBranchElement(
      document.getElementById('swiper-slide-' + this.json.stringify(this.renderedCourseTemplateTreeBranches[2])),
    );
    return;
  }

  private async goToPreviousSlide(): Promise<void> {
    if (this.currentCourseTemplateTreeBranchIndex <= 0) {
      return;
    }
    this.currentCourseTemplateTreeBranchIndex--;
    this.changeIndexEvent.emit({ index: this.currentCourseTemplateTreeBranchIndex });

    if (this.currentCourseTemplateTreeBranchIndex >= this.courseTemplateTreeBranches.length - 2) {
      this.renderedCourseTemplateTreeBranches.unshift(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex - 1],
      );
      this.changeDetector.detectChanges();
      this.activateCurrentSlide();
      return;
    }

    this.renderedCourseTemplateTreeBranches.pop();
    if (this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex - 1] !== undefined) {
      this.renderedCourseTemplateTreeBranches.unshift(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex - 1],
      );
      this.changeDetector.detectChanges();
    }
    this.activateCurrentSlide();
    if (this.hasNextTreeRootChanged() === true) {
      await this.careerPathAlgorithmHelper.removeDateSkippedFromAlgorithmParameters(
        this.renderedCourseTemplateTreeBranches[1].id,
      );
    }
    return;
  }

  private async activateCurrentSlide(): Promise<void> {
    const previouslyActiveBranch = document.getElementsByClassName('active-slide')[0];
    previouslyActiveBranch?.classList.remove('active-slide');

    document
      .getElementById(
        'swiper-slide-' +
          this.json.stringify(this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex]),
      )
      ?.classList.add('active-slide');

    this.changeDetector.detectChanges();
  }

  private resetMarginsOfBranchElement(branch: HTMLElement): void {
    if (!branch) {
      return;
    }
    branch.style.marginLeft = '0';
  }

  private async getRootCourseIdOfNextTreeToSkip(): Promise<number | undefined> {
    const skippedPathIds = await this.careerPathAlgorithmHelper.getSkippedPathIds();
    const unskippedPaths = this.courseTemplateTreeBranches.filter((x) => skippedPathIds.includes(x.id) === false);
    if (!unskippedPaths || unskippedPaths.length === 0) {
      return undefined;
    }
    const rootCourseIdOfNextTreeToSkip = unskippedPaths[0].id;
    return rootCourseIdOfNextTreeToSkip;
  }

  private async loadMoreBranches(): Promise<void> {
    this.isLoading = true;
    const rootCourseIdOfNextTreeToSkip = await this.getRootCourseIdOfNextTreeToSkip();
    if (rootCourseIdOfNextTreeToSkip !== undefined) {
      await this.careerPathAlgorithmHelper.addDateSkippedToAlgorithmParameters(rootCourseIdOfNextTreeToSkip);
    }
    const courseTemplateTreesSearchResult = await this.careerPathHelper.searchCourseTemplateTrees(
      this.searchedCourseTemplateTreeParameter,
      true,
    );

    if (courseTemplateTreesSearchResult === SYSTEM_ERROR_CODE) {
      this.isLoading = false;
      throw Error(this.courseHelper.errorCollection);
    }
    if (courseTemplateTreesSearchResult === STANDARD_ERROR_CODE) {
      this.isLoading = false;
      console.error(
        'Fehlerhafte Suchparameter' +
          JsonValidationHelper.validateJson(this.searchedCourseTemplateTreeParameter, CourseTemplateTreeParameterJson),
      );
    }
    if ((courseTemplateTreesSearchResult as CourseTemplateTree[]).length === 0) {
      this.isLoading = false;
      this.careerPathAlgorithmHelper.resetAllPaths();
      this.loadMoreBranches();
      this.changeDetector.detectChanges();
      return;
    }
    const newBranches = await this.careerPathHelper.buildCourseTemplateTrees(
      courseTemplateTreesSearchResult as CourseTemplateTree[],
    );
    if (this.renderedCourseTemplateTreeBranches.length === 3) {
      this.courseTemplateTreeBranches.splice(this.currentCourseTemplateTreeBranchIndex + 2);
      this.changeDetector.detectChanges();
      await this.updateRenderedBranches();
    }
    this.courseTemplateTreeBranches = this.courseTemplateTreeBranches.concat(newBranches);
    if (
      this.renderedCourseTemplateTreeBranches.length === 2 &&
      this.currentCourseTemplateTreeBranchIndex !== (0 && undefined)
    ) {
      this.renderedCourseTemplateTreeBranches.push(
        this.courseTemplateTreeBranches[this.currentCourseTemplateTreeBranchIndex + 1],
      );
      this.changeDetector.detectChanges();
    }
    this.updateBranchesEvent.emit({ branches: this.courseTemplateTreeBranches });
    this.storageHelper.setSessionStorage(HAS_CAREER_SEARCHED_LOGICAL_NAME, true);
    await this.careerPathAlgorithmHelper.createParametersForCurrentTree(
      this.courseTemplateTreeBranches[this.courseTemplateTreeBranches.length - 1].id,
    );

    this.loadedTreesInLoadingCycle += 1;
    if (newBranches.length === 1 || this.loadedTreesInLoadingCycle === 1) {
      this.loadMoreBranches();
      this.changeDetector.detectChanges();
      return;
    }
    await this.unskipTreesInCurrentLoadingCycle();
    this.isLoading = false;
    this.changeDetector.detectChanges();
    return;
  }

  private async unskipTreesInCurrentLoadingCycle(): Promise<void> {
    const skippedPathIds: number[] = await this.careerPathAlgorithmHelper.getSkippedPathIds();

    const currentLoadingCycleSkippedPathIds = skippedPathIds.slice(
      skippedPathIds.length - this.loadedTreesInLoadingCycle,
      skippedPathIds.length,
    );

    for (const id of currentLoadingCycleSkippedPathIds) {
      await this.careerPathAlgorithmHelper.removeDateSkippedFromAlgorithmParameters(id);
    }

    this.loadedTreesInLoadingCycle = 0;
  }

  private hasPreviousTreeRootChanged(): boolean {
    if (this.renderedCourseTemplateTreeBranches[0]?.id !== this.renderedCourseTemplateTreeBranches[1]?.id) {
      return true;
    }
    false;
  }

  private hasNextTreeRootChanged(): boolean {
    if (this.renderedCourseTemplateTreeBranches[2]?.id !== this.renderedCourseTemplateTreeBranches[1]?.id) {
      return true;
    }
    false;
  }
}
