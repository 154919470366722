<div class="swiper-container" id="career-path-container" [appScrollBottom]="content"
  [style.pointer-events]="isSwiperCooldown ? 'none' : 'auto'">
  <div class="swiper-slide display-grid" id="swiper-slide-first"
    *ngIf="this.currentCourseTemplateTreeBranchIndex === 0 && renderedCourseTemplateTreeBranches.length !== 1">
    <div class="career-path-card">
    </div>
  </div>
  <div class="swiper-slide display-grid" (click)="isSwiperCooldown === false ? doubleClickToggleFavorite.emit() : {}"
    *ngFor="let courseTemplateTreeBranchItem of renderedCourseTemplateTreeBranches; let i = index" [attr.data-index]="i"
    id="swiper-slide-{{json.stringify(courseTemplateTreeBranchItem)}}">
    <div class="career-path-card">
      <div id="{{json.stringify(courseTemplateTreeBranchItem)}}-stamp"></div>
      <div class="display-grid">
        <ion-img draggable="false" draggable="false" class="one-icon" src="../../assets/apprenticeship-icon.svg">
        </ion-img>
        <ion-text class="one">
          1
        </ion-text>
        <ion-img draggable="false" class="two-icon" src="../../assets/journeyman-icon.svg">
        </ion-img>
        <ion-text class="two">
          2
        </ion-text>

        <div class="depth-1-text-box center-element"
          (click)="isSwiperCooldown ? null : courseClicked(courseTemplateTreeBranchItem.id, $event)">
          <ion-text class="depth-1-text">
            <ng-container *ngIf="selectedRequirementQualificationCategory !==  null &&
              selectedRequirementQualificationCategory !==  undefined">
              {{selectedRequirementQualificationCategory?.name}}<br />
            </ng-container>
            {{selectedRequirementQualificationType?.name}}
          </ion-text>
        </div>

        <div class="depth-2-text-box center-element"
          (click)="isSwiperCooldown ? null : courseClicked(courseTemplateTreeBranchItem.id, $event)">
          <ion-row>
            <ion-col size="11" class="margin-padding-0">
              <ion-text class="depth-2-text">
                {{courseTemplateTreeBranchItem?.name}}&nbsp;
              </ion-text>
            </ion-col>
            <ion-col size="1" class="margin-padding-0 center-element">
              <ion-icon name="information-circle"></ion-icon>
            </ion-col>
          </ion-row>
        </div>

        <div *ngIf="courseTemplateTreeBranchItem?.hasOwnProperty('nextBranch') === false" class="career-path">
        </div>
        <ion-img draggable="false" *ngIf="courseTemplateTreeBranchItem?.hasOwnProperty('nextBranch') === false"
          class="career-path" src="../../assets/tree-path/arrow-depth-2.svg ">
        </ion-img>
        <ion-text class="goal-text-depth-2"
          *ngIf="courseTemplateTreeBranchItem?.hasOwnProperty('nextBranch') === false">
          Der Weg zu deinem<br />
          persönlichen Ziel
        </ion-text>
      </div>

      <div class="display-grid" *ngIf="courseTemplateTreeBranchItem?.hasOwnProperty('nextBranch') === true">
        <ion-img draggable="false" class="three-icon" src="../../assets/master-icon.svg ">
        </ion-img>
        <ion-text class="three">
          3
        </ion-text>

        <div class="depth-3-text-box center-element"
          (click)="isSwiperCooldown ? null : courseClicked(courseTemplateTreeBranchItem.nextBranch.id, $event)">
          <ion-row>
            <ion-col size="11" class="margin-padding-0">
              <ion-text class="depth-3-text">
                {{courseTemplateTreeBranchItem.nextBranch?.name}}&nbsp;
              </ion-text>
            </ion-col>
            <ion-col size="1" class="margin-padding-0 center-element">
              <ion-icon name="information-circle"></ion-icon>
            </ion-col>
          </ion-row>
        </div>

        <ion-img draggable="false" class="career-path"
          *ngIf="courseTemplateTreeBranchItem.nextBranch?.hasOwnProperty('nextBranch') === false"
          src="../../assets/tree-path/arrow-depth-3.svg ">
        </ion-img>
        <ion-text class="goal-text-depth-3">
          Der Weg zu deinem<br />
          persönlichen Ziel
        </ion-text>
      </div>
    </div>
  </div>
  <div class="swiper-slide display-grid" id="swiper-slide-last" *ngIf="renderedCourseTemplateTreeBranches.length < 3 &&
    currentCourseTemplateTreeBranchIndex > 1">
    <div class="career-path-card">
    </div>
  </div>
  <ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif" class="background-loading-animation"
    *ngIf="isLoading === true"></ion-img>
</div>
