<div id="close-chatbot-invisible-layer" (click)="closeChat()" *ngIf="isChatOpen===true">
</div>
<ion-row class="chatbot-bubble-row" (click)="closeChat()">
  <ion-col size="11" size-md="10" class="display-grid" (click)="closeChat()">
    <form [formGroup]="chatForm" id="chat-window" class="chat-window" *ngIf="isChatOpen===true"
      (click)="$event.stopPropagation()">
      <div class="flex chatbot-header">
        <strong class="ion-margin-start align-self-center">Chatbot</strong>
        <ion-text class="beta-icon ion-margin-start ion-align-items-center display-flex">beta</ion-text>
        <button id="chat-close-button" class="close-button" (click)="closeChat()">
          <ion-icon name="close"></ion-icon>
        </button>
      </div>
      <ion-card class="card-tabs margin-padding-0 border-radius-0
        box-shadow-none">
        <ion-segment [swipeGesture]="false" [value]="currentChatBotTab">
          <ion-segment-button (click)="changeChatBotTab($any($event.target).value)" [value]="messageType.BotMessage">
            <ion-label class="margin-padding-0">
              {{messageType.BotMessage}}
            </ion-label>
          </ion-segment-button>
          <ion-segment-button (click)="changeChatBotTab($any($event.target).value)" [value]="messageType.SystemMessage">
            <ion-label class="margin-padding-0">
              {{messageType.SystemMessage}}
              <ion-icon *ngIf="areSystemNotificationsUnread === true" name="alert-circle"
                class="highlighted-text"></ion-icon>
            </ion-label>

          </ion-segment-button>
        </ion-segment>
      </ion-card>
      <div id="chat-output" *ngIf="currentChatBotTab === messageType.BotMessage">
        <!--hier landet der Chat-Output von Chatbot und User-->
      </div>
      <div id="system-output" *ngIf="currentChatBotTab === messageType.SystemMessage">
        <!--hier landet der Chat-Output vom System-->
      </div>
      <ion-text id="chat-input" *ngIf="currentChatBotTab === messageType.BotMessage">
        <ion-row class="border-top-1">
          <ion-col *ngIf="isUserAgreementAgreed === true" size="8">
            <ion-textarea maxlength="255" formControlName="userInputChat"
              (keyup)="changeUserInput($any($event.target).value)" (keyup.enter)="triggerChatSend()">
            </ion-textarea>
          </ion-col>
          <ion-col class="ion-justify-content-end display-grid"
            *ngIf="isUserAgreementAgreed !== true && isLoggedIn !== true">
            <ng-container>
              <ion-button class="ion-margin" (click)="yesButtonClicked()">Einwilligen</ion-button>
            </ng-container>
          </ion-col>
          <ion-col *ngIf="isUserAgreementAgreed === true" class="justify-content-center align-items-center display-grid"
            size="4">
            <ion-button (click)="triggerChatSend()" [disabled]="chatForm.valid === false">
              <ion-icon name="send"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-text>
      <ion-text id="chat-input" *ngIf="currentChatBotTab !== messageType.BotMessage">
        <ion-row class="margin-top-1px">
          <ion-col>
            <ion-textarea [disabled]="true">
            </ion-textarea>
          </ion-col>
        </ion-row>
      </ion-text>
    </form>
  </ion-col>
</ion-row>
