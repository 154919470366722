import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SYSTEM_ERROR_CODE } from 'app/models/constants-status';
import { ErrorMessageParameter } from 'app/models/error-message-parameter';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { JsonValidationHelper } from '../helpers/json-validation-helper';
import { Course } from '../models/course.model';
import { CourseParameter } from '../models/course_parameter.model';
import CourseJson from '../validators/course_v1-0-2.json';
import RequestStatusJson from '../validators/request_status_v1-0-0.json';
import { RequestStatus } from './../models/request-status.model';
import { ErrorMessageService } from './error-message.service';

@Injectable({
  providedIn: 'root',
})
export class GetCoursesService {
  public errorMessage = '';
  constructor(
    private http: HttpClient,
    private errorMessageService: ErrorMessageService,
  ) {}
  public async searchCourses(courseParameter: CourseParameter): Promise<Course[] | number> {
    try {
      const options = {
        headers: new HttpHeaders({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${environment.authenticationToken}`,
        }),
      };

      const body = JSON.stringify(courseParameter);
      const courseObservable = this.http.post<RequestStatus>(environment.apiUrlCourses, body, options);
      const courses: Course[] = [];

      const requestStatus = await lastValueFrom(courseObservable);

      // Kurse validieren
      if (JsonValidationHelper.validateJson(requestStatus, RequestStatusJson) !== true) {
        const validation = JSON.stringify(JsonValidationHelper.validateJson(requestStatus, RequestStatusJson));
        if (environment.production === false) {
          console.error(validation);
        }
        if (environment.production === true) {
          const error: ErrorMessageParameter = new ErrorMessageParameter();
          error.description = validation;
          this.errorMessageService.reportErrorMessage(error);
        }
        return courses;
      }
      requestStatus.data.forEach((course) => {
        if (JsonValidationHelper.validateJson(course, CourseJson) !== true) {
          const validation = JSON.stringify(JsonValidationHelper.validateJson(course, CourseJson));
          if (environment.production === false) {
            console.error(validation);
            console.error('Fehlerhafter Kurs "' + course.name + '" mit ID: ' + course.id);
          }
          if (environment.production === true) {
            const error: ErrorMessageParameter = new ErrorMessageParameter();
            error.description = validation;
            this.errorMessageService.reportErrorMessage(error);
          }
          return;
        }
        if (course.description === null) {
          course.description = '';
        }
        courses.push(course);
      });

      return courses;
    } catch (error) {
      this.errorMessage = error.message;
      return SYSTEM_ERROR_CODE;
    }
  }
}
