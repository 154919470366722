import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FormHelper } from 'app/helpers/form-helper';
import { JsonValidationHelper } from 'app/helpers/json-validation-helper';
import { UserHelper } from 'app/helpers/user-helper';
import { DATA_PROTECTION_QUESTION, USER_AGREEMENT_QUESTION } from 'app/models/constants-app-texts';
import {
  NEUTRAL_ERROR_CODE,
  STANDARD_ERROR_CODE,
  STANDARD_ERROR_TEXT,
  SUCCESS_ERROR_CODE,
  SYSTEM_ERROR_CODE,
  USER_DATA_INPUT_ERROR_CODE,
} from 'app/models/constants-status';
import { UserRegistrationParameter } from 'app/models/user-registration-parameter.model';
import { RegisterUserService } from 'app/services/register-user.service';
import { environment } from 'environments/environment';
import UserRegistrationParameterJson from '../../../validators/user_registration_parameter_v1-0-0.json';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.page.html',
  styleUrls: ['./user-registration.page.scss'],
})
export class UserRegistrationPage implements OnInit {
  public isLoading = false;
  public formGroup = this.formBuilder.group({
    firstNameInput: ['', Validators.required],
    lastNameInput: ['', Validators.required],
    emailInput: ['', [Validators.required, Validators.email]],
    userAgreementCheckbox: [false, Validators.requiredTrue],
    dataProtectionCheckbox: [false, Validators.requiredTrue],
  });
  public errorCode = NEUTRAL_ERROR_CODE;
  public isLoggedIn = false;
  public readonly passwordResetUrl = environment.passwordResetUrl;
  public readonly standardErrorCode = STANDARD_ERROR_CODE;
  public readonly userDataInputErrorCode = USER_DATA_INPUT_ERROR_CODE;
  public readonly successErrorCode = SUCCESS_ERROR_CODE;
  public readonly USER_AGREEMENT_QUESTION = USER_AGREEMENT_QUESTION;
  public readonly DATA_PROTECTION_QUESTION = DATA_PROTECTION_QUESTION;

  public isUserAgreementAgreed = false;
  public isDataProtectionAgreed = false;

  public selectedUserRegistrationParameter: UserRegistrationParameter = new UserRegistrationParameter();

  constructor(
    public formHelper: FormHelper,
    public sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private userRegistrationService: RegisterUserService,
    private userHelper: UserHelper,
  ) {}

  async ngOnInit() {
    this.isLoggedIn = await this.userHelper.checkForUser();
  }

  async ionViewWillEnter() {
    this.isLoggedIn = await this.userHelper.checkForUser();
  }

  refresh(event) {
    setTimeout(() => {
      event.target.complete();
      window.location.reload();
    }, 1000);
  }

  public changeSelectedFirstName(firstName: string): void {
    this.selectedUserRegistrationParameter.firstName = firstName;
  }

  public changeSelectedLastName(lastName: string): void {
    this.selectedUserRegistrationParameter.lastName = lastName;
  }

  public changeSelectedEmail(email: string): void {
    this.selectedUserRegistrationParameter.email = email;
  }

  public async pressLogoutButton(): Promise<void> {
    this.isLoggedIn = this.userHelper.logoutUser();
  }

  public async pressRegistrationButton(): Promise<void> {
    await this.registerUser();
  }

  public pressNoEmailReceivedButton(): void {
    this.errorCode = NEUTRAL_ERROR_CODE;
  }

  public async changeIsUserAgreementAgreed(isUserAgreementAgreed: any): Promise<void> {
    this.isUserAgreementAgreed = isUserAgreementAgreed;
  }

  public async changeIsDataProtectionAgreed(isDataProtectionAgreed: any): Promise<void> {
    this.isDataProtectionAgreed = isDataProtectionAgreed;
  }

  public async registerUser(): Promise<void> {
    this.isLoading = true;

    if (
      JsonValidationHelper.validateJson(this.selectedUserRegistrationParameter, UserRegistrationParameterJson) !== true
    ) {
      this.errorCode = this.userDataInputErrorCode;
      this.isLoading = false;
      console.error(
        STANDARD_ERROR_TEXT +
          JsonValidationHelper.validateJson(this.selectedUserRegistrationParameter, UserRegistrationParameterJson),
      );
    }

    const errorCode = await this.userRegistrationService.registerUser(this.selectedUserRegistrationParameter);

    if (errorCode === STANDARD_ERROR_CODE) {
      this.errorCode = errorCode;
      this.isLoading = false;
      console.error(STANDARD_ERROR_TEXT);
    }
    if (errorCode === SYSTEM_ERROR_CODE) {
      this.errorCode = errorCode;
      this.isLoading = false;
      console.error(this.userRegistrationService.errorMessage);
    }

    if (errorCode === 10) {
      console.error('Es gibt bereits einen Nutzer mit deiner Email Adresse.');
      this.errorCode = errorCode;
      this.isLoading = false;
      return;
    }

    // Registrierung erfolgreich
    this.errorCode = this.successErrorCode;
    this.isLoading = false;

    return;
  }
}
