<ion-img src="./../../../../assets/Hubgrade_Loading_Animation.gif" class="general-loading-animation"
  *ngIf="isCourseLoading === true || areFavoritesLoading === true || isPageLoading === true"></ion-img>
<ion-card class="ion-text-center margin-slider-card" *ngIf="courseTemplateTreeBranches?.length <= 0">
  <h1 class="ion-text-center ion-margin">Die Suche fand keine interessanten Karrierepfade.</h1>
  <ion-row class="ion-margin-bottom">
    <ion-col offset="1" size="10" size-md="5">
      <ion-button expand="block" *ngIf="this.hasPotentialResultsInCareerSearch"
        aria-label="zurück zu Vorigen Ergebnissen" (click)="careerPathAlgorithmHelper.resetAllPaths();"
        alt="Zu letzten Ergebnissen" default-href="#">Vorige
        Ergebnisse</ion-button>
    </ion-col>
    <ion-col offset="1" size="10" offset-md="0" size-md="5">
      <ion-button expand="block" (click)="setHasSearched(false); browserInteractionHelper.refresh();">Neu
        Suchen</ion-button>
    </ion-col>
  </ion-row>
</ion-card>
<ng-container *ngIf="courseTemplateTreeBranches?.length > 0 && isPageLoading === false">
  <ion-row>
    <app-swiper [amountOfPreviousCareerBranches]="amountOfPreviousCareerBranches" [content]="content"
      [courseTemplateTreeBranches]="courseTemplateTreeBranches"
      [selectedRequirementQualificationCategory]="selectedRequirementQualificationCategory"
      [selectedRequirementQualificationType]="selectedRequirementQualificationType" [isCourseLoading]="isCourseLoading"
      (doubleClickToggleFavorite)="doubleClickToggleFavorite()"
      (setDoesLikeClickAddLikeEvent)="setDoesLikeClickAddLike()"
      (courseClickedEvent)="courseClicked($event.courseId, $event.event)"
      (changeIndexEvent)="setCurrentBranch($event.index); markStamps($event.index)"
      [searchedCourseTemplateTreeParameter]="searchedCourseTemplateTreeParameter"
      (updateBranchesEvent)="updateBranches($event.branches)"
      [isDateSkipped]="isDateSkipped"
      (isDateSkippedDoneEvent)="isDateSkipped = false"
      (changeIsSwiperCooldownEvent)="$event.isSwiperCooldown">
    </app-swiper>
  </ion-row>
  <ion-row id="thumb-button-row" class="justify-content-center" *ngIf="currentCourseTemplateTreeBranch !== undefined">
    <button class="unfavorite-swiper-button" (click)="clickDislikeButton()" [disabled]="isButtonCooldown">
      <div class="thumb-unfavorite"></div>
    </button>
    <button class="favorite-swiper-button" (click)="clickLikeButton()" [disabled]="isLikingDisabled()">
      <div class="thumb-favorite"></div>
    </button>
  </ion-row>
</ng-container>
